import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import IExam from '../models/IExam';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { BASE_URL } from '../injection-tokens/base-url.token';

/**
 * Service for interacting with Exams.
 */
@Injectable({
  providedIn: 'root'
})
export class ExamService {
   /**
    * Dictionary of cached exams by language code
    */
   private cachedExams = new Map<string, IExam[]>();

   /**
    * Empty constructor.
    *
    * @param httpClient used to make http calls
    * @param baseUrl base url for the app
    * @param _authenticationService used to get secure headers.
    */
   public constructor(
     private httpClient: HttpClient,
     @Inject(BASE_URL) private baseUrl: string,
     private _authenticationService: AuthenticationService
   ) { }

   /**
    * Returns EFH Supported exams.
    *
    * @param languageCode string for the language code
    * @returns Observable list of IExam objects.
    */
   public getEFHSupportedExams(languageCode: string): Observable<IExam[]> {
      return this.httpClient.get<IExam[]>(this.baseUrl + `api/exams?languageCode=${languageCode}`);
   }

   /**
    * Retrieves an Exam via the exam code and language code.
    *
    * @param examCode
    * @param languageCode
    * @returns Promise resolving to an IExam object. May be undefined.
    */
   public async getExam(examCode: string, languageCode: string): Promise<IExam | undefined> {
      if (!this.cachedExams.has(languageCode)) {
         this.cachedExams.set(languageCode, await this.getEFHSupportedExams(languageCode).toPromise());
      }
      return this.cachedExams.get(languageCode)?.find((exam: IExam) => exam.ExamSeriesCode === examCode);
   }

   // Caches exams for multiple languages asynchronously
   public async precacheExams(languageCodes: string[]) {
      const uniqueLanguages = [...new Set(languageCodes)];
      await Promise.all(
         uniqueLanguages.map(async (lang) => {
            if (!this.cachedExams.has(lang))
               this.cachedExams.set(lang, await this.getEFHSupportedExams(lang).toPromise());
         }));
   }
}
