<app-header-body-separator [accessCode]="accessCode"></app-header-body-separator>
<app-alert *ngIf="hasError" [state]="'error'" i18n>
   {{message}}
   <app-technical-support [showTechError]="showTechError"></app-technical-support>
</app-alert>
<div>
   <h1 class="main-title" i18n>Exams from Home</h1>

   <div>
      <h2 i18n>Current Center: {{ ExamSession?.testingCenterName }} ({{ ExamSession?.testingCenterId }}) </h2>
      <h2 i18n>Important Notes!</h2>
      <ul>
         <li i18n>
            The smaller windows display the Test Candidates' exams in progress, please do not click in or hover over them. And please <strong>keep your window at full screen while proctoring</strong>, do not resize your window!
         </li>
         <li i18n>
            If the Test Candidate experiences an error, they will simply need to click on the link you provided them in order to reestablish the session. If necessary, have them reboot their computer and then try the link again.
         </li>
         <li>
            <span i18n>Chat Notification Legend</span>
            <div class="legend-item-container"><span id="countLegend" class="legend-1" i18n>3</span>&nbsp;<span class="legend-content" i18n>Number of chat notifications</span></div>
            <div class="legend-item-container"><img class="" src="/images/infoNotification.jpg" />&nbsp;<span class="legend-content" i18n>New or unread chat(s)</span></div>
            <div class="legend-item-container"><img class="" src="/images/replyNotification.jpg" />&nbsp;<span class="legend-content" i18n>Viewed chat notification</span></div>
         </li>
      </ul>
   </div>

   <hr />

   <h1 i18n>Candidate Machines</h1>

   <div *ngFor="let vm of candidateVms">
      <div>
         <hr />
         <app-candidate-session [candidateVM]="vm" [accessCode]=accessCode></app-candidate-session>
      </div>
   </div>
</div>

