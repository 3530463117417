<div class="test-center-selection-container" [formGroup]="timeForm">
   <label class="exam-date-time" [ngClass]="error" i18n>Specify start time*
      <fa-icon [icon]="infoCircle" i18n-matTooltip matTooltip="The exact time you would like your exam session to start."
               matTooltipPosition="right">
      </fa-icon>
   </label>
   <br>
   <mat-form-field id="proctor-chat-name" class="text-field" appearance="outline">
      <mat-label i18n>Select Time</mat-label>
      <input matInput type="time" formControlName="time" required (focusout)="isValid()">
   </mat-form-field>
</div>
