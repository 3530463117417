import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, OnInit, AfterViewInit, Renderer2, ViewChild, Input } from '@angular/core';
import { SignalRChatService } from '../services/signal-rchat.service';

@Component({
   selector: 'app-chatnotification',
   templateUrl: './chatnotification.component.html',
   styleUrls: ['./chatnotification.component.scss']
})
export class ChatnotificationComponent implements AfterViewInit {
   machineName: any;
   userName: any;
   @Input() accessCode!: string;

   constructor(
      private renderer: Renderer2,
      private signalrChat: SignalRChatService,
      private viewportScroller: ViewportScroller) {
   }

   @ViewChild('notificationDropdown')
   notificationDropdown!: ElementRef;

   ngOnInit(): void {
      console.log(" chatNotification ngOnInit - signalrChat is: " + this.signalrChat);
      console.log("accesscode is: " + this.accessCode);

      //Wire up SignalR
      this.signalrChat.initConnection();
      console.log("Wiring up login cand listener");
      this.signalrChat.addReceiveLoggedInCandidateListener((symlink, displayName) => {
         this.AddSymlinkToNotificationList(symlink, displayName);
         this.notifyMessageReceived();
      });

      console.log("Wiring up message listener");
      this.signalrChat.addReceiveMessageListener((symlink, username) => {
         this.AddSymlinkToNotificationList(symlink, username);
         this.notifyMessageReceived();
      });
      this.signalrChat.start(this.accessCode);
      this.signalrChat.logID();
   }
   ngAfterViewInit(): void {
      //chatNotification div chatnotification button and arrow button
      var bttnDiv = this.renderer.createElement('div');
      bttnDiv.id = "notificationsDiv";
      this.renderer.appendChild(this.notificationDropdown.nativeElement, bttnDiv);

      var drpDwn = this.renderer.createElement("input");
      drpDwn.type = "button";
      drpDwn.id = "notificationBarButton";
      drpDwn.value = $localize`Chat Notifications`;
      drpDwn.addEventListener("click", (e: Event) => this.getnotificationDropdown());

      var drpDwn2 = this.renderer.createElement("input");
      drpDwn2.type = "button";
      drpDwn2.id = "arrow";
      var arrowStyle = document.createAttribute("class");
      arrowStyle.value = "arr-down";
      drpDwn2.setAttributeNode(arrowStyle);
      drpDwn2.addEventListener("click", (e: Event) => this.getnotificationDropdown());



      var circleDiv = <HTMLElement>document.createElement("span");
      circleDiv.id = "countDiv";
      circleDiv.nodeValue = "enabled";
      var circleStyle = document.createAttribute("style");
      circleStyle.value = "height: 20px;width: 20px;background-color:  #ff0000;border-radius: 50%;text-align:center; color:#fff;float:left; margin-left:5px; margin-top:5px;";
      circleDiv.setAttributeNode(circleStyle);
      circleDiv.style.display = "none";
      this.renderer.appendChild(bttnDiv, circleDiv);
      this.renderer.appendChild(bttnDiv, drpDwn);
      this.renderer.appendChild(bttnDiv, drpDwn2);

      var soundImg = this.renderer.createElement("input");
      soundImg.type = "image";
      soundImg.src = "/images/SoundOn.svg";
      soundImg.id = "notificationSound";
      soundImg.value = "enabled";
      soundImg.addEventListener("click", (e: Event) => this.notificationSoundOnOff());
      this.renderer.appendChild(bttnDiv, soundImg);



      var innerDiv = this.renderer.createElement("div");
      innerDiv.id = "notificationDropdownList";
      innerDiv.style.display = "none;";
      this.renderer.appendChild(this.notificationDropdown.nativeElement, innerDiv);



   }
   notificationSoundOnOff(): void {
      var img = <HTMLInputElement>document.getElementById("notificationSound");
      var x = <HTMLAudioElement>document.getElementById("messageReceivedNotification");
      if (img.value === "enabled") {
         img.src = "/images/SoundOff.svg";
         img.value = "disabled";
      }
      else {
         img.src = "/images/SoundOn.svg";
         img.value = "enabled";
      }
   };
   getnotificationDropdown(): void {
      var div = <HTMLElement>document.getElementById("notificationDropdownList");
      div.style.display === 'block' ? div.style.display = 'none' : div.style.display = 'block';
      this.displayNoNotifications(div);
      var div2 = <HTMLElement>document.getElementById("arrow");
      div2.id = ("arrow");
      div2.className === "arr-up" ? div2.className = "arr-down" : div2.className = "arr-up";
   }
   AddSymlinkToNotificationList(symlink: string, userName: string): void {
      console.log("AddMachineToNotif with machine: " + symlink + " and user: " + userName);
      var div = <HTMLElement>document.getElementById("notificationDropdownList");
      var alertDiv = document.getElementById("candidate_" + symlink);
      if (alertDiv == null) {
         var para = this.renderer.createElement("p");
         para.className = "notificationMachineBox";
         para.id = "candidate_" + symlink;



         var img = this.renderer.createElement("img");
         img.id = "image_" + symlink;
         img.className = "machineNotificationImage";
         img.src = "/images/infoNotification.jpg";
         this.renderer.appendChild(para, img);

         var close = this.renderer.createElement("button");
         close.addEventListener("click", (e: Event) => this.removeNotification("candidate_" + symlink));
         close.textContent = 'X';
         this.renderer.appendChild(para, close);

         var a = this.renderer.createElement("a");
         var hrefAttr = document.createAttribute("href");
         a.setAttributeNode(hrefAttr);
         a.innerHTML = userName.toString();
         a.addEventListener("click", (e: Event) => this.setNotification(symlink, 'read'));
         a.addEventListener("click", (e: Event) => {
            e.preventDefault();
            this.navigateToFragment('sessionInfo_' + symlink);
         });
         this.renderer.appendChild(para, a);

         var text = this.renderer.createElement("text");
         text.innerHTML = userName.toString();
         this.renderer.appendChild(para, text);
         this.renderer.appendChild(div, para);
      }
      else {
         this.setNotification(symlink, 'updated');
      }
      this.UpdateNotificationCount();
   };

   showHideNotificationList(): void {
      var div = <HTMLElement>document.getElementById("notificationDropdownList");
      if (div.hidden) {
         div.style.display = 'block';
      }
      else {
         div.style.display = 'none';
      }

   };

   getCount(parent: any, getChildren: any): number {

      var relevantChildren = 0;
      var children = parent?.childNodes.length;
      for (var i = 0; i < children; i++) {
         if (parent?.childNodes[i].nodeType !== 3
            && parent?.childNodes[i].__ng_removed === undefined) { //removeChild doesn't work in realtime so we need to check if it has been marked for deletion.
            if (getChildren)
               relevantChildren += this.getCount(parent?.childNodes[i], true);
            relevantChildren++;
         }
      }
      return relevantChildren;
   }

   UpdateNotificationCount(): void {
      var element = document.getElementById("notificationDropdownList");
      var count = this.getCount(element, false);
      var circleDiv = document.getElementById("countDiv");
      if (count > 0) {
         circleDiv!.innerText = count.toString();
         circleDiv!.style.display = "inline-block";
         this.removeNotification("noNotifications");
      } else {
         circleDiv!.style.display = "none";
         this.displayNoNotifications(element);
      }
   }

   displayNoNotifications(element: any): void {
      if (this.getCount(element, false) === 0) {
         var para = this.renderer.createElement("p");
         para.className = "notificationMachineBox";
         para.id = "noNotifications";
         var text = this.renderer.createElement("text");
         text.className = "noNotifications";
         text.innerHTML = $localize`No new notifications.`;
         this.renderer.appendChild(para, text);
         this.renderer.appendChild(element, para);
      }
   }

   notifyMessageReceived(): void {
      var img = <HTMLInputElement>document.getElementById("notificationSound");
      if (img.value == "enabled") {
         var x = <HTMLAudioElement>document.getElementById("messageReceivedNotification");
         console.log('playing notif sound');
         var playSound = x.play();
         if (playSound !== undefined) {
            playSound.then(_ => {
               console.log("playing sound");
            })
               .catch(error => {
                  console.log('Play sound error:', error);
               });
         }
      }
   }


   alertNotification(evt: any): void {
      this.AddSymlinkToNotificationList(evt.data, this.userName);
   };

   removeNotification(symlink: any): void {
      var notificationDiv: any = document.getElementById(symlink);
      if (notificationDiv != null && notificationDiv.__ng_removed === undefined) { //angular does not remove elements in realtime so we need this __ng_removed check. This is why we should've implemented chat notifications as components rather than as a straight port from the old code. /rant
         var innerDiv = document.getElementById("notificationDropdownList");
         this.renderer.removeChild(innerDiv, notificationDiv);
         this.UpdateNotificationCount();
      }
   };

   setNotification(symlink: any, status: any) {
      var imgID = this.renderer.createElement("image_" + symlink);
      if (status == "read") {
         imgID.src = "/images/replyNotification.jpg";
      }
      else if (status == "updated") {
         imgID.src = "/images/infoNotification.jpg";
      }
   };
   navigateToFragment(fragmentID: string): void {
      this.viewportScroller.scrollToAnchor(fragmentID);
   }

}






