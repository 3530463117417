import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'ctp-button',
   templateUrl: './ctp-button.component.html',
   styleUrls: ['./ctp-button.component.scss']
})
export class CtpButtonComponent implements OnInit {
   @Input() style = 'primary';
   constructor() { }

   ngOnInit(): void {
   }

}
