import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageBoxResult } from '../MessageBoxResult';

@Component({
   selector: 'app-yes-no-message-box',
   templateUrl: './yes-no-message-box.component.html',
   styleUrls: ['./yes-no-message-box.component.scss']
})
export class YesNoMessageBoxComponent implements OnInit {

   constructor(private _dlgRef: MatDialogRef<YesNoMessageBoxComponent>,
   @Inject(MAT_DIALOG_DATA) public message: string) { }

   ngOnInit(): void {
   }

   public handleClickYes(): void {
      this._dlgRef.close(MessageBoxResult.yes);
   }

   public handleClickNo(): void {
      this._dlgRef.close(MessageBoxResult.no);
   }
}
