import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ITestCenter } from '../../../models/IProctorInfo';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
   selector: 'app-test-center-selection',
   templateUrl: './test-center-selection.component.html',
   styleUrls: ['./test-center-selection.component.scss']
})
export class TestCenterSelectionComponent implements OnInit {
   @Input() testCenterId = '';
   @Output() validate = new EventEmitter();
   testCenters: ITestCenter[];
   infoCircle = faInfoCircle;
   hasError = false;
   private firstFocus = true;

   constructor(private authenticationService: AuthenticationService) {
      this.testCenters = [];
   }

   ngOnInit(): void {
      this.authenticationService.ProctorSource.subscribe(proctor => {
         this.testCenters = [... new Map(proctor.testCenters.map(item => [item['CustomerID'], item])).values()];
      });
   }

   isValid() : boolean {
      if(this.testCenterId === ''){
         this.hasError = true;
         return false;
      }
      this.hasError = false;
      return true;
   }

   async validateInline() {
      await new Promise(f => setTimeout(f, 100));
      if (!this.firstFocus) {
         this.validate.emit();
      }
      this.firstFocus = false;
   }
}
