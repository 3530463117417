<div class="language-filter-container">
   <label for="language-filter" class="exam-language" [ngClass]="error" i18n>Exam language(s)</label>
   <mat-form-field id="language-filter" appearance="outline" class="select-one">
      <mat-label i18n>Select one</mat-label>
      <mat-select [(ngModel)]="languageCode" (selectionChange)="selectionChange($event.value)" required>
         <mat-option *ngFor="let lang of languages" value="{{lang.languageCode}}">
            {{lang.languageName}}
         </mat-option>
      </mat-select>
   </mat-form-field>
</div>
