import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";

export interface ISignalRHub {
   initialize(url: string): void;
   start(): Promise<void>;
   on(methodName: string, newMethod: () => void): void;
   invoke(methodName: string, ...args: any[]): Promise<void>;
}

@Injectable({
   providedIn: 'root'
})
export class SignalRHub implements ISignalRHub {
   private hubConnection!: signalR.HubConnection;

   constructor() { }

   initialize(url: string): void {
      this.hubConnection = new signalR.HubConnectionBuilder()
         .withUrl(url)
         .withAutomaticReconnect()
         .configureLogging(signalR.LogLevel.Debug)
         .build();
   }

   start(): Promise<void> {
      return this.hubConnection.start();
   }

   on(methodName: string, newMethod: (...args: any[]) => void): void {
      this.hubConnection.on(methodName, newMethod);
   }

   invoke(methodName: string, ...args: any[]): Promise<void> {
      console.log("");
      return this.hubConnection.invoke(methodName, ...args)
         .then(
            (x) => console.log("invoke success: " + x), //fulfilled
            (x) => console.error("invoke rejected: " + x) //reject
         )
         .catch(error => console.error("invoke catch: " + error));
   }

   logID(): void {
      console.log("ProctorConnectionID: " + this.hubConnection.connectionId + " at url: " + this.hubConnection.baseUrl + ". status: " + this.hubConnection.state);
   }
}
