import { Component, Input, OnInit} from '@angular/core';
import { ExamSessionService } from '../services/exam-session.service';

@Component({
  selector: 'app-header-body-separator',
  templateUrl: './header-body-separator.component.html',
  styleUrls: ['./header-body-separator.component.scss']
})
export class HeaderBodySeparatorComponent implements OnInit {
   @Input() accessCode: any;
   
   constructor(private examSessionService: ExamSessionService) { }

   ngOnInit(): void {}

}
