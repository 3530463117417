import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import ICandidateVm from '../../../models/ICandidateVm';

@Component({
   selector: 'app-candidate-vm',
   templateUrl: './candidate-vm.component.html',
   styleUrls: ['./candidate-vm.component.scss']
})
export class CandidateVmComponent implements OnInit {
   @Input() candidateVM!: ICandidateVm;
   iframeSrcUrl: any;

   constructor(private sanitizer: DomSanitizer) { }

   ngOnInit(): void {
      this.iframeSrcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.candidateVM.joinUrl);
   }
}
