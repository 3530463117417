import { Component, Inject, Optional, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { EmailValidationBase } from "../../../services/email-validation.service";
import { DialogData } from "../session-confirmation.component";

@Component({
   selector: 'email-dialog',
   templateUrl: 'email-dialog.component.html',
   styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent {
   local_data: { email: string; emailConfirm: string; };
   email: string = '';
   timesIcon = faTimes;
   showEmailReqValidation = false;
   showCnfEmailReqValidation = false;
   showEmailMatchValidation = false;
   closeDialogBox = false;

   constructor(@Optional() public dialogRef: MatDialogRef<EmailDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData, public emailValidator: EmailValidationBase) {
      this.email = data.email;
      this.local_data = { email: '', emailConfirm: '' };
   }

   submit() {
      this.emailOnBlur();
      this.cnfEmailOnBlur();

      if (!this.showEmailReqValidation && !this.showCnfEmailReqValidation && !this.showEmailMatchValidation) {
         this.dialogRef.close({ data: this.local_data });
      }
   }

   emailOnBlur() {
      if (!this.closeDialogBox) {
         this.showEmailReqValidation = !this.emailValidator.isValidAddress(this.local_data.email);
         this.checkEmailMismatch();
      }
   }

   cnfEmailOnBlur() {
      if (!this.closeDialogBox) {
         this.showCnfEmailReqValidation = !this.emailValidator.isValidAddress(this.local_data.emailConfirm);
         this.checkEmailMismatch();
      }
   }

   checkEmailMismatch() {
      this.showEmailMatchValidation = false

      if (this.local_data.email.trim().length > 0 && this.local_data.emailConfirm.trim().length > 0 && this.local_data.email != this.local_data.emailConfirm) {
         this.showEmailMatchValidation = true;
      }
   }

   closeDialog() {
      this.closeDialogBox = true;
      this.dialogRef.close({ data: { email: '', emailConfirm: '' } });
   }
}
