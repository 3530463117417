import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CandidatesInExamService, ICandidate } from '../services/candidates-in-exam.service';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { faSearch } from '@fortawesome/pro-light-svg-icons';


@Component({
   selector: 'app-candidates-in-exam',
   templateUrl: './candidates-in-exam.component.html',
   styleUrls: ['./candidates-in-exam.component.scss']
})
export class CandidatesInExamComponent {
   search = "";
   searchIcon = faSearch;
   angleUpIcon = faAngleUp;
   angleDownIcon = faAngleDown;
   candidatesInExamCount = 0;
   sessionCandidatesCount = 0;
   candidates: ICandidate[] = [];
   filteredCandidates: ICandidate[] = [];
   keyUp = new BehaviorSubject<string>("");
   showCandidatesList = false;
   searchPlaceHolder: string = $localize`Search`;

   constructor(private candidatesInExamService: CandidatesInExamService) {
      this.keyUp.pipe(
         debounceTime(500),
         distinctUntilChanged()
      ).subscribe(
         searchTextValue => {
            this.filteredCandidates = this.candidates.filter(c => c.FirstName.toLowerCase().includes(searchTextValue.toLowerCase()) || c.LastName.toLowerCase().includes(searchTextValue.toLowerCase()))
         }
      );
   }

   ngOnInit(): void {
      this.candidatesInExamService.getCandidatesCount().subscribe((candidatesCount) => {
         this.sessionCandidatesCount = candidatesCount;
      }
      );

      this.candidatesInExamService.getCandidate().subscribe((candidateSubject) => {
         if (this.candidates.filter(c => c.CandidateId == candidateSubject.CandidateId).length == 0) {
            let candidate: ICandidate = {
               CandidateId: candidateSubject.CandidateId,
               FirstName: candidateSubject.FirstName,
               LastName: candidateSubject.LastName,
               Symlink: candidateSubject.Symlink
            };

            this.candidates.push(candidate);

            if (this.search.trim().length == 0 || candidate.FirstName.toLowerCase().includes(this.search.toLowerCase()) || candidate.LastName.toLowerCase().includes(this.search.toLowerCase())) {
               this.filteredCandidates.push(candidate)
            }

            this.candidatesInExamCount = this.candidates.length;
         }
      }
      );
   }

   clearSearchBox() {
      this.search = '';
      this.filteredCandidates = this.candidates;
   }

   ToggleShowCandidateListValue() {
      this.showCandidatesList = !this.showCandidatesList;
   }

   ScrollToCandidate(symlink: string) {
      let id = `#sessionInfo_${symlink}`;
      document.querySelector(id)?.scrollIntoView({ behavior: 'smooth' });
   }

   showClearSearchIcon() {
      return (this.search.trim().length > 0);
   }
}
