import { Component, Input, OnInit } from '@angular/core';
import ICandidateVm from '../../../models/ICandidateVm';

@Component({
   selector: 'app-candidate-exam-complete',
   templateUrl: './candidate-exam-complete.component.html',
   styleUrls: ['./candidate-exam-complete.component.scss']
})
export class CandidateExamCompleteComponent implements OnInit {
   @Input() candidateVM!: ICandidateVm;

   constructor() { }

   ngOnInit(): void {
   }

}
