import { Component, Input, OnInit } from '@angular/core';

import { AppConstants } from '../../constants/AppConstants';

@Component({
   selector: 'app-technical-support',
   templateUrl: './technical-support.component.html',
   styleUrls: ['./technical-support.component.scss']
})
export class TechnicalSupportComponent implements OnInit {
   @Input('showTechError') showTechError: boolean = false;

   supportURL: string = AppConstants.SupportURL;

   constructor() { }

   ngOnInit(): void {
   }
}
