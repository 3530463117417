import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILanguage } from '../models/ILanguage';
import { AuthenticationService } from './authentication.service';
import { BASE_URL } from '../injection-tokens/base-url.token';


@Injectable({
   providedIn: 'root'
})
export class LanguageService {

   constructor(private httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, private _authenticationService: AuthenticationService) { }

   getLanguages(): Observable<ILanguage> {
      const url = this.baseUrl + 'api/EfhLanguage/languages';
      return this.httpClient.get<ILanguage>(url);

   }
}
