import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { StartExamGuard } from './guards/start-exam.guard';
import { ExamsViewPageComponent } from './pages/exams-view-page/exams-view-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SessionManagementPageComponent } from './pages/session-management-page/session-management-page.component';
import { SystemErrorComponent } from './pages/start-session-error/system-error.component';

const routes: Routes = [
   { path: '', component: SessionManagementPageComponent, canActivate: [AuthGuard] },
   { path: 'exams-view', component: ExamsViewPageComponent, canActivate: [StartExamGuard] },
   { path: 'login', component: LoginPageComponent },
   { path: 'system/error', component: SystemErrorComponent },
   { path: '**', redirectTo: '' }
   /*{ path: '', redirectTo: '/session-management', pathMatch: 'full' }*/
];

@NgModule({
   imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
