<div id="sessionInfo_{{candidateVMInfo.symlink}}" class="session-info">
   <div class="candidate-session-container">
      <label i18n>Candidate: </label> <span class="candidate-info"><strong>{{candidateVMInfo.candidateFirstName}} {{candidateVMInfo.candidateLastName}}</strong> </span><span i18n> (ID:</span><span> {{candidateVMInfo.candidateId}})</span><span class="candidate-info-spacer">&nbsp;</span>
      <label i18n>Machine Number: </label><span class="candidate-info">{{candidateVMInfo.symlink}} </span>
   </div>
   <p i18n>EXAM STATUS: <em>In Progress</em></p>
   <div>
      <ctp-button [style]="'primary'" (click)="onClickRefresh()" i18n>Refresh</ctp-button>&nbsp;&nbsp;<ctp-button [style]="'primary'" (click)="onClickRestartVM()" i18n>Restart VM</ctp-button>

   </div>
</div>
