<div class="namesessions">
   <p i18n>
      Note: The session name will not be displayed to the candidate and is only for your reference.
   </p>
   <label class="medium-bold" [ngClass]="error" i18n>
      Specify session name*
   </label>
   <div class="names">
      <input type="text" [(ngModel)]='sessionName' appAutofocus />
   </div>
</div>
