import { Injectable } from '@angular/core';
import {TimeZone} from "../models/TimeZone";

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor() { }

   getTimeZone(timeZone: string){
      switch(timeZone) {
         case TimeZone.ALASKA:
            return $localize`Alaska`;
         case TimeZone.PACIFIC:
            return $localize`Pacific Time (US & Canada)`;
         case TimeZone.MOUNTAIN:
            return $localize`Mountain Time (US & Canada)`;
         case TimeZone.CENTRAL:
            return $localize`Central Time (US & Canada)`;
         case TimeZone.EASTERN:
            return $localize`Eastern Time (US & Canada)`;
         case TimeZone.BOGOTA:
            return $localize`Bogota, Lima, Quito, Rio Branco`;
         case TimeZone.GUADALAJARA:
            return $localize`Guadalajara, Mexico City, Monterrey`;
         case TimeZone.CARACAS:
            return $localize`Caracas`;
         case TimeZone.CUIABA:
            return $localize`Cuiaba`;
         case TimeZone.BUENOS_AIRES:
            return $localize`City of Buenos Aires`;
         case TimeZone.GEORGETOWN:
            return $localize`Georgetown, La Paz, Manaus, San Juan`;
         case TimeZone.MID_ATLANTIC:
            return $localize`Mid-Atlantic - Old`;
         case TimeZone.AZORES:
            return $localize`Azores`;
         case TimeZone.CABO_VERDE:
            return $localize`Cabo Verde Is.`;
         case TimeZone.DUBLIN:
            return $localize`Dublin, Edinburgh, Lisbon, London`;
         case TimeZone.WEST_CENTRAL_AFRICA:
            return $localize`West Central Africa`;
         case TimeZone.CASABLANCA:
            return $localize`Casablanca`;
         case TimeZone.SARAJEVO:
            return $localize`Sarajevo, Skopje, Warsaw, Zagreb`;
         case TimeZone.CHISINAU:
            return $localize`Chisinau`;
         case TimeZone.KUWAIT:
            return $localize`Kuwait, Riyadh`;
         case TimeZone.ABU_DHABI:
            return $localize`Abu Dhabi, Muscat`;
         case TimeZone.ISTANBUL:
            return $localize`Istanbul`;
         case TimeZone.MOSCOW:
            return $localize`Moscow, St. Petersburg`;
         case TimeZone.TBILISI:
            return $localize`Tbilisi`;
         case TimeZone.BAKU:
            return $localize`Baku`;
         case TimeZone.HAWAII:
            return $localize`Hawaii`;
         case TimeZone.TEHRAN:
            return $localize`Tehran`;
         case TimeZone.ISLAMABAD:
            return $localize`Islamabad, Karachi`;
         case TimeZone.CHENNAI:
            return $localize`Chennai, Kolkata, Mumbai, New Delhi`;
         case TimeZone.KATHMANDU:
            return $localize`Kathmandu`;
         case TimeZone.DHAKA:
            return $localize`Dhaka`;
         case TimeZone.YANGON:
            return $localize`Yangon (Rangoon)`;
         case TimeZone.KUALA_LUMPUR:
            return $localize`Kuala Lumpur, Singapore`;
         case TimeZone.BEIJING:
            return $localize`Beijing, Chongqing, Hong Kong, Urumqi`;
         case TimeZone.SEOUL:
            return $localize`Seoul`;
         case TimeZone.DARWIN:
            return $localize`Darwin`;
         case TimeZone.CANBERRA:
            return $localize`Canberra, Melbourne, Sydney`;
         case TimeZone.SOLOMON_IS:
            return $localize`Solomon Is., New Caledonia`;
         case TimeZone.BRISBANE:
            return $localize`Brisbane`;
         case TimeZone.ADELAIDE:
            return $localize`Adelaide`;
         case TimeZone.FIJI:
            return $localize`Fiji`;
         case TimeZone.AUCKLAND:
            return $localize`Auckland, Wellington`;
         case TimeZone.NUKU_ALOFA:
            return $localize`Nuku'alofa`;
         case TimeZone.KIRITIMATI_IS:
            return $localize`Kiritimati Island`;
         default:
            return `${timeZone}`
      }
   }
}
