import { Component, Inject, OnInit } from '@angular/core';
import { LabmanagerService } from '../services/labmanager.service';
import ICandidateVm from '../models/ICandidateVm';
import { SignalRService } from '../services/signal-r.service';
import { ExamSessionService } from '../services/exam-session.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationsService } from '../services/configurations.service';
import { MatDialog } from '@angular/material/dialog';
import { SurveyDialogComponent } from '../Controls/survey-dialog/survey-dialog.component';
import { SurveyDialogResult } from '../Controls/SurveyDialogResult';
import { IFullExamSession } from '../models/IFullExamSession';
import { AuthenticationService } from '../services/authentication.service';
import { IExamSessionSettings } from '../models/IExamSessionSettings';
import { BASE_URL } from '../injection-tokens/base-url.token';
import { CandidatesInExamService, ICandidate } from '../services/candidates-in-exam.service';

@Component({
   selector: 'app-exams-view',
   templateUrl: './exams-view.component.html',
   styleUrls: ['./exams-view.component.scss']
})
export class ExamsViewComponent implements OnInit {
   accessCode: any;
   candidateVms: ICandidateVm[] = [];
   surveylink: string = '';
   message: string = '';
   hasError: boolean = false;
   showTechError: boolean = false;

   public ExamSession!: IFullExamSession;
   public examSessionSettings!: IExamSessionSettings;
   private LatestStartTime!: Date;

   constructor(
      private authenticationService: AuthenticationService,
      private labManagerService: LabmanagerService,
      private proctorHub: SignalRService,
      @Inject(BASE_URL) private baseUrl: string,
      private examSessionService: ExamSessionService,
      private route: ActivatedRoute,
      private configurations: ConfigurationsService,
      private _dialog: MatDialog
      , private candidatesInExamService: CandidatesInExamService) {

   }

   ngOnInit(): void {
      this.route.queryParams.subscribe(
         params => {
            this.accessCode = params['accessCode'];

            this.GetSurveyLink();
            this.GetExamSessionInfo();
            this.GetCandidatesVmDetailsAndLoad();
         }
      );
   }

   private GetSurveyLink() {
      this.configurations.getSurveyLink().subscribe(response => {
         this.surveylink = response;
      }, error => {
         this.displayError($localize`Survey link was uable to fetch.`);
      });
   }

   private GetExamSessionInfo() {

      this.examSessionService.getExamSessionByAccessCode(this.accessCode).subscribe(async response => {
         this.ExamSession = response;
         this.candidatesInExamService.setCandidatesCount(this.ExamSession.candidateCount);
         this.GetExamSessionSettings();
      }, error => {
         this.displayError($localize`Examsession info was unable to fetch.`);
      });
   }

   private GetExamSessionSettings() {

      this.authenticationService.JwtSource.subscribe(jwt => {
         if (jwt) {
            this.examSessionService.getExamSessionSettings().subscribe(sessionSettings => {
               this.examSessionSettings = sessionSettings;
               this.SetDisplaySurveyDialogTriggerTime();
            }, error => {
               this.displayError($localize`Failed to fetch Examsession Settings.`);
            });
         }
      }, error => {
         this.displayError($localize`Authentication failed to fetch Examsession Settings.`);
      });
   }

   private GetCandidatesVmDetailsAndLoad() {

      this.labManagerService.getCandidateVMs(this.accessCode).subscribe(
         (response) => {
            this.candidateVms = response.sort((c1, c2) => this.compareCandidateVms(c1, c2));

            this.SetCandidateInfo();
            this.decodeRdpUrls(this.candidateVms);
            this.StartProctorHubConnection();
         },
         (error) => {
            this.displayError($localize`We are unable to retrieve the full list of Candidates in the session. Please refresh your browser.`, true);
         }
      );
   }

   private SetCandidateInfo() {
      this.candidateVms.forEach(element => {
         let candidate: ICandidate = { CandidateId: element.candidateId, FirstName: element.candidateFirstName, LastName: element.candidateLastName, Symlink: element.symlink };
         this.candidatesInExamService.setCandidate(candidate);
      });
   }

   private decodeRdpUrls(candidateVms: ICandidateVm[]): void {
      for (const vm of candidateVms)
         vm.joinUrl = atob(vm.joinUrl);
   }

   private StartProctorHubConnection() {
      this.proctorHub.startConnection(this.accessCode)
         .then(() => {
            this.InitializeHubListeners();
         });
   }

   private InitializeHubListeners() {
      this.CandidateLoggedInListener();
      this.ExamCompleteListener();
      this.CandidateAutoRefreshListner();
   }

   private CandidateLoggedInListener() {
      this.proctorHub.addCandidateLoggedInListener((accessCode: string, symlink: string, joinUrl: string, candidateId: string, candidateFirstName: string, candidateLastName: string, assignedToCandidateTime: string) => {
         let isCandidateVmExists = this.candidateVms.findIndex(s => s.candidateId == candidateId);
         if (isCandidateVmExists === -1) {
            this.candidateVms.push({
               candidateId: candidateId,
               candidateFirstName: candidateFirstName,
               candidateLastName: candidateLastName,
               symlink: symlink,
               accessCode: accessCode,
               joinUrl: atob(joinUrl),
               assignedToCandidateTime: assignedToCandidateTime,
               examCompleted: false,
               isRestarting: false
            });
         }
         else {
            let iframeElement = document.getElementById(`iframe_${symlink}`) as HTMLIFrameElement;
            iframeElement.src = iframeElement.src;
         }
         this.candidateVms.sort((c1, c2) => this.compareCandidateVms(c1, c2));

         let candidate: ICandidate = { CandidateId: candidateId, FirstName: candidateFirstName, LastName: candidateLastName, Symlink: symlink };
         this.candidatesInExamService.setCandidate(candidate);
      });
   }

   private compareCandidateVms(c1: ICandidateVm, c2: ICandidateVm): number {
      return new Date(c1.assignedToCandidateTime).getTime() - new Date(c2.assignedToCandidateTime).getTime();
   }

   private ExamCompleteListener() {

      this.proctorHub.addCompleteExamListener((symlink: string) => {
         let candidateVm = this.candidateVms.find(cvm => cvm.symlink == symlink);
         if (candidateVm) {
            candidateVm.examCompleted = true;
         }

         let nowTime = this.convertToUTCTime(new Date()).getTime();

         if (nowTime > this.LatestStartTime.getTime()) {
            this.DisplaySurveyDialog();
         }
      });
   }

   private CandidateAutoRefreshListner() {

      this.proctorHub.addCandidateAutoRefreshListner((symlink: string) => {
         setTimeout(() => {
            let iframeElement = document.getElementById(`iframe_${symlink}`) as HTMLIFrameElement;
            iframeElement.src = iframeElement.src;
         }, 5000);
      });
   }

   private SetDisplaySurveyDialogTriggerTime() {
      this.LatestStartTime = this.getStartTimes(this.ExamSession).latestStartTime;
      let latestTime = this.LatestStartTime.getTime();

      let nowTime = this.convertToUTCTime(new Date()).getTime();
      if (nowTime < latestTime) {
         setTimeout(() => {
            this.DisplaySurveyDialog();
         }, latestTime - nowTime);
      }
   } 

   private DisplaySurveyDialog() {
      if (this.surveylink != null && this.surveylink != '') {
         if (this.candidateVms.filter(c => c.examCompleted == false).length == 0) {
            const dlgRef = this._dialog.open(SurveyDialogComponent, { panelClass: 'survey-dialog', height: '25.625rem', width: '50.313rem' });

            dlgRef.afterClosed().subscribe(result => {
               if (result == SurveyDialogResult.TakeSurvey) {
                  window.open(this.surveylink, '_blank');
               }
            });
         }
      }
   }

   private convertToUTCTime(date: Date) {
      var utcDateTime = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      return utcDateTime;
   }

   private getStartTimes(examSession: IFullExamSession) {
      let examStartTime = new Date(examSession.startTime);
      let earliestStartTime = new Date(examStartTime);
      let latestStartTime = new Date(examStartTime);
      earliestStartTime.setMinutes(earliestStartTime.getMinutes() - this.examSessionSettings.earlyStartMinutes);
      latestStartTime.setMinutes(latestStartTime.getMinutes() + this.examSessionSettings.lateStartMinutes);
      return { earliestStartTime, latestStartTime };
   }

   private displayError(message: string, showTechError: boolean = false) {
      this.message = message;
      this.hasError = true;
      this.showTechError = showTechError;
   }
}

