import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { EmailDialogComponent } from "./email-dialog/email-dialog.component";
import { EmailService } from "../../services/email.service";
import IEmailInfo from "../../models/IEmailInfo";
import { AuthenticationService } from "../../services/authentication.service";
import { IExamSessionInput } from "../../models/IExamSessionInput";
import { environment } from '../../../environments/environment';
import { IProctor, ITestCenter } from '../../models/IProctorInfo';
import { ExamSessionService } from "../../services/exam-session.service";
import { SpinnerService } from '../../services/spinner.service';
import { AppConstants } from '../../constants/AppConstants';


export interface DialogData {
   email: string,
   emailConfirm: string
}

@Component({
   selector: 'app-session-confirmation',
   templateUrl: './session-confirmation.component.html',
   styleUrls: ['./session-confirmation.component.scss']
})
export class SessionConfirmationComponent implements OnInit {
   @Input() sessionInfo!: IExamSessionInput;
   @Input() accessCode = '';
   @Output() emailSent = new EventEmitter();
   @Output() onSubmit = new EventEmitter();

   alertHidden: string = 'hidden';
   sendButtonHidden: string = '';
   resendButtonHidden: string = 'hidden';
   message: string = '';
   status: string = '';

   proctor = {} as IProctor;
   proctorEmail: string = $localize`email@email.com`;
   emailConfirm: string = '';
   showTechError: boolean = false;

   private proctorRole = 3;
   private allowedRoles = [76, 23];

   constructor(public dialog: MatDialog,
      private authenticationService: AuthenticationService,
      private route: ActivatedRoute,
      private emailService: EmailService,
      private spinnerService: SpinnerService,
      private examSessionService: ExamSessionService) {
   }

   ngOnInit(): void {
      this.alertHidden = 'hidden';
      this.route.queryParams.subscribe(params => {
         console.log(params);
         this.authenticationService.ProctorSource.subscribe(proctor => {
            this.proctorEmail = proctor.Email;
            this.proctor = proctor;
            this.nonProctorEmail();
         });
      });
   }

   sendEmail() {
      this.spinnerService.show();
      this.hideAlert();
      if (this.proctorEmail != this.sessionInfo.proctorEmail) {
         this.emailService.sendEmail(this.accessCode, this.proctorEmail).subscribe(_ => {
            this.EmailSent();
            this.sessionInfo.proctorEmail = this.proctorEmail;
         },
            error => {
               this.EmailFailure();
            });
      } else {
         this.emailService.sendEmail(this.accessCode).subscribe(data => {
            this.EmailSent();
         },
            error => {
               this.EmailFailure();
            });
      }
   }

   private EmailSent() {
      this.spinnerService.hide();
      this.emailSent.emit();
      this.postSuccess($localize`Email successfully sent to ${this.proctorEmail}. Follow the instructions in the email before starting the exam.`);
      this.sendButtonHidden = 'hidden';
      this.resendButtonHidden = '';
   }

   private EmailFailure() {
      this.spinnerService.hide();
      this.postError($localize`The system failed to send the email. You can try again here or at the dashboard.`, false);
      this.emailSent.emit();
      this.sendButtonHidden = 'hidden';
      this.resendButtonHidden = '';
}

   async updateEmail() {
      const dialogRef = this.dialog.open(EmailDialogComponent, {
         data: { email: this.proctorEmail, emailConfirm: this.emailConfirm }
      });
      await dialogRef.afterClosed().subscribe(result => {

         if (result.data == null) {
            this.postError($localize`Something went wrong...`, true);
         } else if (result.data.email != '') {
            this.postSuccess($localize`You have successfully updated your email address.`);
            this.proctorEmail = result.data.email;
         } else {
            this.hideAlert();
         }
      });
   }

   postSuccess(message: string) {
      this.message = message;
      this.status = 'success';
      this.alertHidden = '';
      this.showTechError = false;
   }

   postError(message: string, showTechError: boolean = false) {
      this.message = message;
      this.status = 'error';
      this.alertHidden = '';
      this.showTechError = showTechError;
   }

   hideAlert() {
      this.alertHidden = 'hidden';
   }

   nonProctorEmail(): void {
      if (this.proctor) {
         let proctorCenter = this.proctor.testCenters.filter(x => x.CustomerID === this.sessionInfo.testCenterId && x.RoleID !== this.proctorRole);
         if (proctorCenter && proctorCenter.some(x => this.allowedRoles.includes(x.RoleID))) {
            this.postError($localize`Please send confirmation email to Proctor.`);
         }
      }
   }
}
