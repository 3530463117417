import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators'
import { IProctor, IProctorAuthentication } from '../models/IProctorInfo';
import { TimeoutService } from './timeout.service';
import { BASE_URL } from '../injection-tokens/base-url.token';

@Injectable({
   providedIn: 'root'
})
export class AuthenticationService {
   private _proctorSubject = new BehaviorSubject<IProctor>(null as unknown as IProctor);
   private _jwtSubject = new BehaviorSubject('');

   constructor(
      private _httpClient: HttpClient,
      private _timeoutService: TimeoutService,
      @Inject(BASE_URL) private _baseUrl: string
   ) {
      this._timeoutService.timeoutOccurred.subscribe(() => this.Logout());
   }


   public ProctorSource: Observable<IProctor> = this._proctorSubject.pipe(filter(v => v != null));

   public JwtSource: Observable<string> = this._jwtSubject;

   public get Jwt() {
      return localStorage.getItem('proctor_jwt');
   }

   public get Proctor() {
      return this._proctorSubject.getValue();
   }

   public AuthenticateWithSid(sid: string): Observable<boolean> {
      if (sid !== null && sid !== undefined) {
         const url = this._baseUrl + `api/Proctor?sessionId=${sid}`;
         const source = this._httpClient.get<IProctorAuthentication>(url);
         return source.pipe(
            map(
               pa => {
                  this.setJwt(pa.jwt);
                  this.setProctor(pa.proctor);
                  return true;
               },
               () => false
            ),
            tap(() => this._timeoutService.init()),
            catchError(error => {
               this._timeoutService.stop();
               return throwError(error);
            }));
      }
      else {
         return of(false);
      }
   }

   public Authenticate(username: string, password: string): Observable<boolean> {
      if (username && password) {
         const json = { username: username, password: password };
         const source = this._httpClient.post<IProctorAuthentication>(this._baseUrl + 'api/Authentication/Login', json);
         return source.pipe(
            map(
               pa => {
                  this.setJwt(pa.jwt);
                  this.setProctor(pa.proctor);
                  return true
               },
               () => false
            ),
            tap(() => this._timeoutService.init()),
            catchError(error => {
               this._timeoutService.stop();
               return throwError(error);
            }));
      }
      else {
         return of(false);
      }
   }

   public FetchProctor(): Observable<boolean> {
      const source = this._httpClient.get<IProctorAuthentication>(this._baseUrl + 'api/Proctor/Refresh');

      return source.pipe(
         map(
            (pa: IProctorAuthentication) => {
               this.setJwt(pa.jwt);
               this.setProctor(pa.proctor);
               return true;
            },
            () => false
         )
      )
   }

   public Logout(): void {
      this.clearJwt();
      this.clearProctor();
   }

   private setProctor(proctor: IProctor): void {
      this._proctorSubject.next(proctor);
   }

   private clearProctor(): void {
      this._proctorSubject.next(null as unknown as IProctor);
   }

   private setJwt(jwt: string): void {
      localStorage.setItem('proctor_jwt', jwt);
      this._jwtSubject.next(jwt);
   }

   private clearJwt(): void {
      delete localStorage['proctor_jwt'];
      this._jwtSubject.next('');
   }
}
