import {Component, OnInit} from '@angular/core';
import {faUser} from '@fortawesome/pro-solid-svg-icons';
import {SpinnerService} from "../services/spinner.service";
import { AuthenticationService } from '../services/authentication.service';
import { IListOption } from '../Controls/list-option';
import { Router } from '@angular/router';
import { isNull } from '@angular/compiler/src/output/output_ast';

@Component({
   selector: 'app-header',
   templateUrl: './header.component.html',
   styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
   accessCode: string = '';
   proctorName: string = "";
   userSolidIcon = faUser;
   userOptions: IListOption[] = [
      { optionId: 'logout', label: $localize`Log out` }
   ];
   loggedIn: boolean = false;

   constructor(private authenticationService: AuthenticationService,
      private spinnerService: SpinnerService,
      private route: Router) {
   }

   ngOnInit(): void {
      this.spinnerService.show();
      this.authenticationService.ProctorSource.subscribe(proctor => {
         this.proctorName = `${proctor.FirstName} ${proctor.LastName}`
         this.spinnerService.hide();
      });
      this.authenticationService.JwtSource.subscribe(jwt => {
         this.loggedIn = !(!jwt || !jwt.trim());
      });
   }

   handleUserOptionClick(option: IListOption): void {
      if (option.optionId === 'logout')
         this.logout();
   }

   logout(): void {
      this.authenticationService.Logout();
      this.route.navigate(['/login']);
      this.proctorName = '';
   }
}
