import { Component, OnInit, SecurityContext, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import ICandidateVm from '../../../models/ICandidateVm';
import { LOCALE_ID, Inject } from '@angular/core';

@Component({
   selector: 'app-chat-box',
   templateUrl: './chat-box.component.html',
   styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit {
   @Input() candidateVM!: ICandidateVm;
   @Input() chatName!: string;

   chatUrl: string = `https://${this.configurationsService.ChatCName}/chatroom`;
   combinedChatUrl: string = "";
   safeUrl: SafeResourceUrl = "";

   constructor(public sanitizer: DomSanitizer,
      private configurationsService: ConfigurationsService, @Inject(LOCALE_ID) private localeId: string) {
   }

   ngOnInit(): void {
      var chatAppLanguagecode = this.getChatAppLanguageCode();
      this.combinedChatUrl = `${this.chatUrl}?symlink=${this.candidateVM.symlink}&proctor=true&name=${this.chatName}&languageCode=${chatAppLanguagecode}`;
      this.combinedChatUrl = this.sanitizer.sanitize(SecurityContext.URL, this.combinedChatUrl) ?? "";
      this.safeUrl = this.getChatUrl();
   }

   getChatUrl() {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.combinedChatUrl);
   }

   private getChatAppLanguageCode() {
      var chatAppLanguagecode = "en";
      switch (this.localeId) {
         case "es-MX"://latin american spanish
            chatAppLanguagecode = "ESM";
            break;
         case "vi-VN"://vietnam
            chatAppLanguagecode = "VIE";
            break;
         case "zh-CN"://chinese simplified
            chatAppLanguagecode = "CHS";
            break;
         case "zh-TW"://chinese traditonal
            chatAppLanguagecode = "CHT";
            break;
         case "fr-FR"://france
            chatAppLanguagecode = "FRA";
            break;
         case "ko-KR"://korea
            chatAppLanguagecode = "KOR";
            break;
         case "ro-RO"://romania
            chatAppLanguagecode = "ROM";
            break;
         case "pt-BR"://portuguese brazil
            chatAppLanguagecode = "PTB";
            break;
         case "tr-TR"://turkish
            chatAppLanguagecode = "TRK";
            break;
         case "th-TH"://thailand
            chatAppLanguagecode = "THA";
            break;
         case "de-DE"://german
            chatAppLanguagecode = "DEU";
            break;
         case "id-ID"://indonesia
            chatAppLanguagecode = "IND";
            break;
         case "el-GR"://greek
            chatAppLanguagecode = "ELL";
            break;
         default:
            chatAppLanguagecode = "en"
      }
      return chatAppLanguagecode;
   }
}
