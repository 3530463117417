import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../services/authentication.service';
import ILogin from '../../models/ILogin';
import { environment } from '../../../environments/environment';
import { SpinnerService } from '../../services/spinner.service';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
   alertStyle = 'error';
   errorMsg: string = $localize `Error. Invalid username or password.`;
   hasError: boolean = false;
   forbiddenMsg: string = $localize `Error. Only CATC Administrators can login from this page. If you are a Candidate, please ask your Proctor for a Candidate Link.`;
   isForbidden: boolean = false;
   loading = false;
   returnUrl: string = '';
   returnQueryParams!: Params;
   recoveryUrl: string = this.configurationsService.AccountRecoveryURL;
   supportUrl: string = this.configurationsService.SupportUrl;
   candidateSupportUrl: string = this.configurationsService.CandidateSupportUrl;
   efhInfoUrl: string = this.configurationsService.EfhInfoUrl;

   constructor(
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private spinnerService: SpinnerService,
      private configurationsService:ConfigurationsService
   ) {
      // redirect to home if already logged in
      if (this.authenticationService.Jwt) {
         this.router.navigate(['/']);
      }
   }

   ngOnInit(): void {
      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      if (this.route.snapshot.queryParams.accessCode) {
         this.returnUrl = '/exams-view'
         this.returnQueryParams = this.route.snapshot.queryParams;
      }
   }

   onSubmit(login: ILogin) {
      // stop here if form is invalid
      if (!login.username || !login.password) {
         return;
      }

      this.loading = true;
      this.spinnerService.show();
      this.authenticationService.Authenticate(login.username, login.password)
         .pipe(first())
         .subscribe(
            data => {
               this.router.navigate([this.returnUrl], {queryParams: this.returnQueryParams});
               this.spinnerService.hide();
            },
            error => {
               this.spinnerService.hide();
               this.hasError = true;
               this.loading = false;
               if (error.status == HttpStatusCode.Forbidden) {
                  this.isForbidden = true;
               } else {
                  this.isForbidden = false;
               }
            });
   }
}
