<form class="app-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
   <div class="form-group">
      <label id="usernameLabel" for="usernameInput" class="control-label" i18n>Username *</label>
      <input id="usernameInput" type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
      <div *ngIf="showErrors('username')" class="help-inline validation-inline">
         <div class="validation-fail" i18n>Username is required</div>
      </div>
   </div>
   <div class="form-group">
      <label id="passwordLabel" for="passwordInput" class="control-label" i18n>Password *</label>
      <input id="passwordInput" type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
      <div *ngIf="submitted && f.password.errors" class="help-inline validation-inline">
         <div *ngIf="f.password.errors.required" class="validation-fail" i18n>Password is required</div>
      </div>
   </div>
   <div class="btn-section">
      <button [disabled]="loading" class="btn btn-primary btn-right" i18n>
         Login
      </button>
   </div>
</form>
