import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class CandidatesInExamService {

   private candidatesCount = new Subject<number>();
   private candidate = new Subject<ICandidate>();

   setCandidatesCount(candidatesCount: number) {
      this.candidatesCount.next(candidatesCount);
   }
   getCandidatesCount() {
      return this.candidatesCount.asObservable();
   }

   setCandidate(candidate: ICandidate) {
      this.candidate.next(candidate);
   }

   getCandidate() {
      return this.candidate.asObservable();
   }
}


export interface ICandidate {
   CandidateId: string;
   FirstName: string;
   LastName: string;
   Symlink: string;
}

