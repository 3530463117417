import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import ValidationStatus from '../../../models/ValidationStatus';

import { ExamSessionService } from '../../../services/exam-session.service';

@Component({
   selector: 'app-candidate-count',
   templateUrl: './candidate-count.component.html',
   styleUrls: ['./candidate-count.component.scss']
})
export class CandidateCountComponent implements OnInit {
   public readonly minValue: number = 1;

   @Input() public candidateCount = this.minValue;
   @Output() validate = new EventEmitter();

   public infoCircle = faInfoCircle;
   public maxValue: number = 50;
   hasError = false;

   public constructor(private examSessionService: ExamSessionService) { }

   public ngOnInit(): void {
      this.examSessionService.getExamSessionSettings().subscribe(x => {
         this.maxValue = x.maximumCandidateCount;
      });
   }

   /**
    * Checks to see if the input is in a valid state
    *
    * @returns ValidationStatus.
    */
   public ValidateCandidateCount(): ValidationStatus {
      let validationStatus = new ValidationStatus();
      this.hasError = false;

      if (this.candidateCount < this.minValue || this.candidateCount > this.maxValue) {
         validationStatus.SetValidationStatus(false,
            $localize`Number of Candidates is invalid, Enter a number from ${this.minValue} to ${this.maxValue}.`);
         this.hasError = true;
      }

      return validationStatus;
   }

   /**
    * Highlights the text in the input field on focus so that it's easier to change.
    *
    * @param event. The focus event.
    */
   public highlightInput(event: Event) {
      try {
         (<HTMLTextAreaElement>event.target).select();
      } catch (e) { }
   }

   async validateInline() {
      await new Promise(f => setTimeout(f, 100));
      this.validate.emit();
   }
}
