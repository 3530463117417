import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ILanguage} from '../../../models/ILanguage';
import {LanguageService} from '../../../services/language.service';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons';
import { error } from '@angular/compiler/src/util';

interface Language {
   code: string;
   text: string;
}

@Component({
   selector: 'app-language-selection',
   templateUrl: './language-selection.component.html',
   styleUrls: ['./language-selection.component.scss']
})
export class LanguageSelectionComponent implements OnInit {
   @Input() languageCode = ''
   @Output() validate = new EventEmitter();
   @Output() onLanguageChange = new EventEmitter<string>();
   errorMessage:string=''

   @Input() languages: ILanguage[] = [];
   selectControl: FormControl = new FormControl('');
   infoCircle = faInfoCircle;
   hasError = false;
   private firstFocus = true;

   constructor(private languageService: LanguageService) {
   }

   ngOnInit(): void {
   }

   selectionChange(languageCode: string) {
      this.onLanguageChange.emit(languageCode);
   }

   isValid() {
      if (this.languageCode === '' ) {
         this.hasError = true;
         return false;
      }
      this.hasError = false;
      return true;
   }

   async validateInline() {
      await new Promise(f => setTimeout(f, 100));
      if (!this.firstFocus) {
         this.validate.emit();
      }
      this.firstFocus = false;
   }
}
