import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IListOption } from '../list-option';

@Component({
   selector: 'ctp-dropdown-box',
   templateUrl: './ctp-dropdown-box.component.html',
   styleUrls: ['./ctp-dropdown-box.component.scss']
})
export class CtpDropdownBoxComponent implements OnInit {
   @Input() options: IListOption[] = [];
   @Output() optionClick = new EventEmitter<IListOption>();

   constructor() { }

   ngOnInit(): void {
   }

   handleOptionClicked(option: IListOption): void {
      this.optionClick.emit(option);
   }
}
