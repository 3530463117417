<div class="row">
   <h1 i18n>Create or manage exam sessions</h1>
</div>

<div class="row">
   <div class="create-session-container">
      <div class="col-9">
         <div id="create-session" class="content-box">
            <div class="content-box-header">
               <span class="title medium-bold" i18n>Create Session</span>
            </div>
            <div class="content-box-body">
               <app-name-session [sessionName]="prvSessionName"></app-name-session>
            </div>
            <div class="content-box-footer">
               <ctp-button [hidden]=hideCreateSession [style]="'primary'" (click)="onClickCreateSession()" i18n>Create Session</ctp-button>
            </div>
         </div>
      </div>
      <div class="col-3">
         <app-helpfulresources></app-helpfulresources>
      </div>
   </div>
</div>

<div class="row">
   <div class="scheduled-sessions-container">
      <div class="content-box">
         <div class="content-box-header">
            <span class="title medium-bold" i18n>Scheduled Session(s)</span>
         </div>
         <div class="content-box-body">
            <app-session-table (onStartSessionClick)="startSession($event)"
                               (onSendConfirmationEmailClick)="sendConfirmationEmail($event)"
                               (onSessionDetailsClick)="sessionDetails($event)"
                               (onCancelSessionClick)="cancelSession($event)"
                               (onSessionsInProgressMsg)="sessionsInProgressMsg($event)"></app-session-table>
         </div>
      </div>
   </div>
</div>
