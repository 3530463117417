import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { SurveyDialogResult } from '../SurveyDialogResult';

@Component({
   selector: 'app-survey-dialog',
   templateUrl: './survey-dialog.component.html',
   styleUrls: ['./survey-dialog.component.scss']
})
export class SurveyDialogComponent implements OnInit {

   constructor(private _dlgRef: MatDialogRef<SurveyDialogComponent>) { }

   ngOnInit(): void {
   }

   public handleEndSession(): void {
      this._dlgRef.close(SurveyDialogResult.EndSession);
   }

   public handleTakeSurvey(): void {
      this._dlgRef.close(SurveyDialogResult.TakeSurvey);
   }
}
