<div class="program-filter-container">
   <label for="program-filter" class="exam-program" [ngClass]="error" i18n>Filter exams list by Program:</label>
   <mat-form-field id="program-filter" appearance="outline" class="select-one">
      <mat-label i18n>Select one</mat-label>
      <mat-select [(ngModel)]="filteredProgramId" (selectionChange)="selectionChange()" required>
         <mat-option value="0" i18n>Show All</mat-option>
         <mat-option *ngFor="let program of programs" value="{{program.programId}}">
            {{program.programName}}
         </mat-option>
      </mat-select>
   </mat-form-field>
</div>
