<div class="session-table"  *ngIf="ExamSessions.length>0">
   <div class="session-header">
      <div class="session-header-row">
         <div class="session-header-cell medium-bold" i18n>Session name</div>
         <div class="session-header-cell medium-bold" i18n>Access code</div>
         <div class="session-header-cell medium-bold" i18n>Exam name</div>
         <div class="session-header-cell medium-bold" i18n>Language</div>
         <div class="session-header-cell medium-bold" i18n>Candidates</div>
         <div class="session-header-cell medium-bold" i18n>Start date</div>
         <div class="session-header-cell medium-bold" i18n>Start time</div>
         <div class="session-header-cell"></div>
         <div class="session-header-cell"></div>
      </div>
   </div>
   <div class="session-body">
      <div *ngFor="let s of ExamSessions let i = index" class="session-row">
         <div class="session-cell">
            {{s.sessionName}}
         </div>
         <div class="session-cell">
            {{s.accessCode}}
         </div>
         <div class="session-cell">
            {{s.examName}}
         </div>
         <div class="session-cell">
            {{s.languageName}}
         </div>
         <div class="session-cell">
            {{s.candidateCount}}
         </div>
         <div class="session-cell">
            {{s.displayStartTime | date : 'longDate'}}
         </div>
         <div class="session-cell">
            <div class="time-container">
               <input type="time" class="start-time" value="{{s.displayStartTime | date : 'HH:mm'}}" readonly />&nbsp;
               <div class="time-zone-container">
                  <span class="time-zone">{{_translationService.getTimeZone(s.timeZoneName)}}</span>
               </div>
            </div>
         </div>
         <div class="session-cell" *ngIf="s.examState == 'started'">
            <ctp-button *ngIf="canStartSession(s)" [style]="'secondary'" (click)="startSession(i)" i18n>Return to Session</ctp-button>
         </div>
         <div class="session-cell" *ngIf="s.examState != 'started'">
            <ctp-button *ngIf="canStartSession(s)" [style]="'secondary'" (click)="startSession(i)" i18n>Start Session</ctp-button>
         </div>
         <div class="session-cell">
            <ctp-dropdown (onOptionClick)="optionClicked($event, i)" [options]="options" i18n>More</ctp-dropdown>
         </div>
      </div>
   </div>
   {{spinnerHide()}}
</div>
<div class="session-table" *ngIf="ExamSessions.length===0 && sessionMsg">
   <p class="no-sessions" i18n>There are no sessions scheduled.</p>
   {{spinnerHide()}}
</div>
