import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import ICandidateVm from '../../../models/ICandidateVm';
import { IRestartVmInput } from '../../../models/IRestartVmInput';
import { SessionInfoService } from '../../../services/session-info.service';
import { YesNoMessageBoxComponent } from '../../../Controls/yes-no-message-box/yes-no-message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxResult } from '../../../Controls/MessageBoxResult';

@Component({
   selector: 'app-session-info',
   templateUrl: './session-info.component.html',
   styleUrls: ['./session-info.component.scss']
})
export class SessionInfoComponent implements OnInit {
   @Input() candidateVMInfo!: ICandidateVm;
   @Input() accessCode: any;
   @Output() onRestartVMClick = new EventEmitter<boolean>();

   constructor(private sessionInfoService: SessionInfoService,
      private _dialog: MatDialog) { }

   ngOnInit(): void {
   }

   public onClickRefresh(): void {
      let iframeElement = document.getElementById(`iframe_${this.candidateVMInfo.symlink}`) as HTMLIFrameElement;
      iframeElement.src = iframeElement.src;
   }

   public onClickCopyUrl(): void {
      navigator.clipboard.writeText(this.candidateVMInfo.joinUrl);
      alert($localize`URL is copied -- TODO: Provide a move appropriate experience than an alert here.`);//TODO determine if we need JoinUrl
   }


   public onClickRestartVM() {
      const dlgRef = this._dialog.open(YesNoMessageBoxComponent, { height: '14rem', width: '28rem', data: $localize`Are you sure you want to restart the candidate machine?` });
      dlgRef.afterClosed().subscribe(result => {
         if (result == MessageBoxResult.yes) {
            let restartVmInput: IRestartVmInput = {
               symlink: this.candidateVMInfo.symlink,
            }

            this.onRestartVMClick.emit(false);

            this.sessionInfoService
               .restartVM(restartVmInput)
               .subscribe(result => {
               },
                  error => {
                     this.onRestartVMClick.emit(true);
                     console.log(error);
                     alert($localize`Error restarting VM`);
                  });
         }
      });
   }
}
