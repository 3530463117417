import { Component, OnInit } from '@angular/core';

export enum MainPage {
   dashboard = 0,
   wizard = 1
}

@Component({
   selector: 'app-main-content',
   templateUrl: './main-content.component.html',
   styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements OnInit {
   currentPage: MainPage = MainPage.dashboard;
   accessCode: any;

   constructor() { }

   ngOnInit(): void {
   }

   onSubmitWizard(accessCode: string): void {
      this.accessCode = accessCode;
      this.currentPage = MainPage.dashboard;
   }
}
