import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import IExam from "../../../models/IExam";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-exam-table',
  templateUrl: './exam-table.component.html',
  styleUrls: ['./exam-table.component.scss']
})
export class ExamTableComponent implements OnInit {
   @Input() languageCode = '';
   @Input() examId = '';
   @Output() selectExam = new EventEmitter();

   exams: IExam[] = [];
   searchResults: IExam[] = [];

   selectControl: FormControl = new FormControl('');

  constructor() { }

  ngOnInit(): void { }

   examSelected(examSeriesCode: string) {
      this.selectExam.emit(examSeriesCode);
   }
}
