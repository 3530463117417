import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ISpinnerState, SpinnerService } from '../services/spinner.service';

@Component({
   selector: 'app-spinner',
   templateUrl: './spinner.component.html',
   styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

   visible = false;
   spinnerStateChanged!: Subscription;

   constructor(private _spinnerService: SpinnerService) { }

   ngOnInit() {
      this.spinnerStateChanged = this._spinnerService.spinnerState
         .subscribe((state: ISpinnerState) => this.visible = state.show);
   }

   ngOnDestroy() {
      this.spinnerStateChanged.unsubscribe();
   }
}
