import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFullExamSession } from "../../models/IFullExamSession";
import { ExamSessionService } from "../../services/exam-session.service";
import { AuthenticationService } from "../../services/authentication.service";
import { ExamService } from "../../services/exam.service";
import IExam from "../../models/IExam";
import {TimeZoneOffsetService} from '../../services/time-zone-offset.service';
import {TranslationService} from "../../services/translation.service";

@Component({
   selector: 'app-session-details',
   templateUrl: './session-details.component.html',
   styleUrls: ['./session-details.component.scss']
})
export class SessionDetailsComponent implements OnInit {
   @Input() sessionInfo = {
      examSessionId: 0,
      proctorId: '',
      candidateCount: 0,
      languageCode: '',
      languageName: '',
      testingCenterId: 0,
      testingCenterName: '',
      examSeriesCode: '',
      examName: '',
      accessCode: '',
      startTime: new Date(),
      displayStartTime: new Date(),
      regionId: 0,
      regionName: '',
      timeZoneId: 0,
      timeZoneName: '',
      adminSymlink: '',
   } as IFullExamSession;
   @Output() previous = new EventEmitter();
   private exam = {} as IExam;

   constructor(private _examSessionService: ExamSessionService,
               public _authenticationService: AuthenticationService,
               public _examService: ExamService,
               private _timeZoneOffsetService: TimeZoneOffsetService,
               public _translationService: TranslationService) {
   }

   ngOnInit(): void {
      this.getTestCenterName();
      this.getExam();
   }

   getTestCenterName() {
      this._authenticationService.ProctorSource.subscribe(x => {
         this.sessionInfo.testingCenterName = x.testCenters.filter(y => y.CustomerID == this.sessionInfo.testingCenterId).map(y => y.CustomerName)[0];
      })
   }

   async getExam() {
      let exam = await this._examService.getExam(this.sessionInfo.examSeriesCode, this.sessionInfo.languageCode);
      if (exam) {
         this.exam = exam;
         this.sessionInfo.examName = this.exam.ProductName;
         this.sessionInfo.programName = this.exam.ProgramName;
      }
   }

   getTimeZoneOffsetText(examSession: IFullExamSession): string {
      return this._timeZoneOffsetService.formatOffset(examSession.timeZoneOffset);
   }
}

