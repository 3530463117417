<div class="page">
   <app-header></app-header>
   <div class="container">
      <app-spinner></app-spinner>
      <div class="rectangle">
         <router-outlet></router-outlet>
      </div>
   </div>
   <!--TODO: Move the spinner to a more appropriate place.-->
  
   <app-footer></app-footer>
</div>
