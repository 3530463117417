import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IExamSessionInfo } from '../models/IExamSessionInfo';
import { IExamSessionInput } from '../models/IExamSessionInput';
import { Observable, throwError } from 'rxjs';
import { IFullExamSession } from '../models/IFullExamSession';
import { AuthenticationService } from './authentication.service';
import { IExamSessionSettings } from "../models/IExamSessionSettings";
import { text } from '@fortawesome/fontawesome-svg-core';
import { BASE_URL } from '../injection-tokens/base-url.token';
import { IServerConfiguration } from '../models/IServerConfiguration';
import { config } from 'process';
import { catchError, map } from 'rxjs/operators';

@Injectable({
   providedIn: 'root'
})
export class ConfigurationsService {
   private configuration!: IServerConfiguration;

   constructor(
      private _httpClient: HttpClient,
      @Inject(BASE_URL) private _baseUrl: string) { }

   getSurveyLink(): Observable<any> {
      const url = this._baseUrl + `api/ExamSessions/GetSurveyLink`;
      return this._httpClient.get(url, { responseType: 'text' })
   }
   getConfigurationSettings() {
      return this._httpClient.get<IServerConfiguration>(this._baseUrl + `api/ConfigurationSettings/ConfigurationData`)
         .pipe(
            map(
               config => {
                  this.configuration = <IServerConfiguration>config;
               },
            ),
            catchError(error => {
               return throwError(error);
            }));
   }
   get ChatDomain() {
      return this.configuration.chatDomain;
   }
   get AccountRecoveryURL() {
      return this.configuration.accountRecoveryURL;
   }
   get SupportUrl() {
      return this.configuration.supportUrl;
   }
   get CandidateSupportUrl() {
      return this.configuration.candidateSupportUrl;
   }
   get EfhInfoUrl() {
      return this.configuration.efhInfoUrl;
   }

   get ChatCName() {
      return this.configuration.chatCName;
   }

   get OutageNotificationWindow() {
      return this.configuration.outageNotificationWindow;
   }
}
