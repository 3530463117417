<div class="display" (click)="ToggleShowCandidateListValue()" i18n>
   Candidates in exam ({{candidatesInExamCount}} of {{sessionCandidatesCount}})
</div>
<div class="angle">
   <fa-icon *ngIf="!showCandidatesList" [icon]="angleDownIcon" (click)="ToggleShowCandidateListValue()"></fa-icon>
   <fa-icon *ngIf="showCandidatesList" [icon]="angleUpIcon" (click)="ToggleShowCandidateListValue()"></fa-icon>
</div>
<div class="candidates-list-outer" *ngIf="showCandidatesList">
   <div class="candidates-list-inner" *ngIf="candidatesInExamCount > 0">
      <div class="search">
         <div class="searchIcon">
            <fa-icon [icon]="searchIcon"></fa-icon>
         </div>

         <div>
            <input type="text" id="search" #searchBox (keyup)='keyUp.next($any($event.target).value)' [(ngModel)]='search' [placeholder]='searchPlaceHolder'>
         </div>


         <div class="clearIcon">
            <span *ngIf="showClearSearchIcon()" (click)="clearSearchBox()">&#11198;</span>
         </div>
      </div>

      <div class="candidate-list">
         <div class="candidate-name" *ngFor="let candidate of filteredCandidates">
            <span (click)="ScrollToCandidate(candidate.Symlink)"><u> {{candidate.FirstName}} {{candidate.LastName}}</u></span>
         </div>
      </div>
   </div>

   <div class="no-candidates" *ngIf="candidatesInExamCount == 0">
      <span i18n><b>No Candidates</b></span>
   </div>
</div>
