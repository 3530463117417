import { Injectable } from '@angular/core';

export abstract class EmailValidationBase {
   abstract isValidAddress(email: string): boolean;
}

@Injectable({
   providedIn: 'root'
})
export class EmailValidationService extends EmailValidationBase {

   constructor() {
      super();
   }

   isValidAddress(email: string): boolean {
      var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg.test(email);
   }
}
