import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import {faInfoCircle} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-time-selection',
  templateUrl: './time-selection.component.html',
   styleUrls: ['./time-selection.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => TimeSelectionComponent),
         multi: true
      },
      {
         provide: NG_VALIDATORS,
         useExisting: forwardRef(() => TimeSelectionComponent),
         multi: true
      }
   ]
})
export class TimeSelectionComponent implements OnInit, Validator, ControlValueAccessor {
   @Input() time = '';
   @Output() valid: boolean = true;
   infoCircle = faInfoCircle;
   error = '';
   public timeForm!: FormGroup;

   constructor() { }

   ngOnInit(): void {
      this.timeForm = new FormGroup({
         time: new FormControl(this.time, [Validators.required])
      })
   }

   isValid() {
      this.valid = this.timeForm.valid;
      if (this.timeForm.valid) {
         this.error = '';
         return true;
      }
      this.error = 'error';
      return false;
   }

   onTouched: () => void = () => { };

   validate(control: AbstractControl): ValidationErrors | null {
      return this.timeForm.valid ? null : {
         invalidForm: {valid: false, message: $localize`time selection is invalid` } };
   }
   writeValue(val: any): void {
      val && this.timeForm.setValue(val, { emitEvent: false });
   }
   registerOnChange(fn: any): void {
      this.timeForm.valueChanges.subscribe(fn);
   }
   registerOnTouched(fn: any): void {
      this.onTouched = fn;
   }
}
