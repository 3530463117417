<div class="container">
   <app-alert state="{{status}}" [ngClass]="alertHidden">
      {{message}}
      <app-technical-support [showTechError]="showTechError"></app-technical-support>
   </app-alert>
   <h1 class="component-header" i18n>Exam session created</h1>
   <div class="flex-row">
      <div class="flex-col step-one">
         <div class="step no-space">
            <h2 i18n>1. Receive exam session emails</h2>
            <div class="inner-content">
               <div class="edit-email">
                  <p><span i18n>You will be sent two detailed emails to: </span><br><strong class="list-header">{{proctorEmail}}</strong> <a (click)="updateEmail()" i18n>edit</a></p>
               </div>
               <div class="list">
                  <p i18n>You will receive two emails:</p>
                  <ul>
                     <li i18n>Proctor confirmation email</li>
                     <li i18n>Candidate exam access email</li>
                     <ul>
                        <li i18n>Forward this email to your candidates</li>
                     </ul>
                  </ul>
               </div>
            </div>
         </div>
         <div class="step send-button">
            <ctp-button (click)="sendEmail()" [style]="'primary'" [ngClass]="sendButtonHidden" i18n>Send confirmation emails</ctp-button>
            <ctp-button (click)="sendEmail()" [style]="'secondary'" [ngClass]="resendButtonHidden" i18n>Resend confirmation emails</ctp-button>
         </div>
      </div>
      <div class="flex-col">
         <div class="step">
            <h2 i18n>2. Review confirmation emails</h2>
            <div class="inner-content">
               <p class="list-header" i18n>Proctor exam access email includes:</p>
               <ul>
                  <li i18n>A link for you to access your exam session as the proctor</li>
                  <li i18n>Instructions to forward the email to your candidates</li>
               </ul>
            </div>
            <div class="inner-content">
               <p class="list-header" i18n>Candidate exam access email includes:</p>
               <ul>
                  <li i18n>A link for candidates to download and launch the secure browser or Chromebook app</li>
                  <li i18n>The access code needed for candidates to access the exam session</li>
               </ul>
            </div>
         </div>
         <div class="step">
            <h2 i18n>3. Start exam session</h2>
            <div class="inner-content">
               <ul>
                  <li i18n>You may start the exam session 5 minutes prior to the start time</li>
                  <li i18n>Candidate(s) may join as soon as you have started the session</li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</div>
