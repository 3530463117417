import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { IExamSessionInput } from "../models/IExamSessionInput";
import { ExamInputBasicInfoComponent } from "./exam-input-basic-info/exam-input-basic-info.component";
import { ExamSelectionComponent } from "./exam-selection/exam-selection.component";
import { ExamDateTimeAndRegionComponent } from "./exam-date-time-and-region/exam-date-time-and-region.component";
import { ExamSessionService } from "../services/exam-session.service";
import { SpinnerService } from "../services/spinner.service";
import { LanguageService } from "../services/language.service";
import { ExamService } from "../services/exam.service";
import { DateTimeService } from "../services/date-time.service";
import { PrevNextComponent } from "../Controls/prev-next/prev-next.component";
import { AuthenticationService } from "../services/authentication.service";
import {DatePipe, formatDate} from '@angular/common';
import { ITestCenter } from '../models/IProctorInfo';
import {TranslationService} from "../services/translation.service";

export enum WizardPage {
   BasicInput = 0,
   ExamSelection = 1,
   ExamTime = 2,
   Confirmation = 3,

   first = BasicInput,
   last = Confirmation
}

@Component({
   selector: 'app-wizard',
   templateUrl: './wizard.component.html',
   styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {
   @Input() currentPage = WizardPage.first;
   @Input() sessionName = '';
   @Output() submitWizard = new EventEmitter<string>();
   @Output() prvSessionName=new EventEmitter<string>();

   @ViewChild(ExamInputBasicInfoComponent) examInputBasicInfoComponent = new ExamInputBasicInfoComponent(this.spinnerService, this.authenticationService, this.languageService, this.examSessionService);
   @ViewChild(ExamSelectionComponent) examSelectionComponent = new ExamSelectionComponent(this.examService, this.spinnerService);
   @ViewChild(ExamDateTimeAndRegionComponent) examDateTimeAndRegionComponent = new ExamDateTimeAndRegionComponent(this.timeZoneRegionService, this._translationService);
   @ViewChild(PrevNextComponent) prevNextComponent = new PrevNextComponent();

   basicInputNextButtonText: string = $localize`Next`;
   timeDateAndRegionNextButtonText: string = $localize`Submit`;
   confirmationNextButtonText: string = $localize`Return to dashboard`;
   accessCode: string = '';
   accessUrl: string = '';
   submissionError: string = '';
   testCenters: ITestCenter[];
   inSubmission = false;
   windowsCurrentTimeZoneId: string = '';

   sessionInfo = {
      sessionName: '',
      proctorId: 0,
      proctorChatName: '',
      language: '',
      testCenterId: 0,
      testCenterCertiportId: '',
      testCenterName: '',
      examId: '',
      candidateCount: 1,
      examDateTime: '',
      regionId: 0,
      timeZoneId: 0,
      time: '',
      proctorEmail: '',
      accessCode: ''
   } as IExamSessionInput;

   constructor(private examSessionService: ExamSessionService,
      private spinnerService: SpinnerService,
      private authenticationService: AuthenticationService,
      private languageService: LanguageService,
      private examService: ExamService,
      private timeZoneRegionService: DateTimeService,
      private _translationService: TranslationService,
      @Inject(LOCALE_ID) private locale: string) {
      this.testCenters = [];
   }

   ngOnInit(): void {
      this.authenticationService.ProctorSource.subscribe(x => {
         this.sessionInfo.proctorEmail = x.Email;
         this.testCenters = [... new Map(x.testCenters.map(item => [item['CustomerID'], item])).values()];
      });
      this.sessionInfo.sessionName = this.sessionName;
      this.getWindowsCurrentTimezone();
   }

   getWindowsCurrentTimezone() {
      var browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.timeZoneRegionService.getWindowsCurrentTimezone(browserTimezone).subscribe(result => {
         this.windowsCurrentTimeZoneId = result;
      });
   }

   getSafeText(text: any): string {
      if (typeof text === 'string')
         return text;
      return '';
   }

   getNextText() {
      switch (this.currentPage) {
         case WizardPage.BasicInput:
            return this.basicInputNextButtonText;
         case WizardPage.ExamSelection:
            return this.basicInputNextButtonText;
         case WizardPage.ExamTime:
            return this.timeDateAndRegionNextButtonText;
         case WizardPage.Confirmation:
            return this.confirmationNextButtonText;
      }
   }

   public onClickPrevious(): void {
      if (this.currentPage === WizardPage.ExamSelection) {
         this.prevNextComponent.displayNext();
      }

      if (this.currentPage === WizardPage.ExamTime || this.currentPage === WizardPage.Confirmation) {
         this.prevNextComponent.hideNext();
      }

      if (this.currentPage > WizardPage.first) {
         this.currentPage--;
      } else {
         this.submitWizard.emit();
         this.prvSessionName.emit(this.sessionInfo.sessionName);
      }

   }

   public onClickNext(): void {
      if (this.currentPage === WizardPage.BasicInput && this.examInputBasicInfoComponent.validate()) {
         const testCenterId = this.examInputBasicInfoComponent.testCenterSelectionComponent.testCenterId;
         const testCenter: ITestCenter | undefined = this.getTestCenter(Number.parseInt(testCenterId));

         if (testCenter) {
            this.sessionInfo.language = this.getSafeText(this.examInputBasicInfoComponent.languageSelectionComponent.languageCode);
            this.sessionInfo.testCenterId = testCenter.CustomerID;
            this.sessionInfo.testCenterCertiportId = testCenter.CertiportID;
            this.sessionInfo.testCenterName = testCenter.CustomerName;
            this.sessionInfo.candidateCount = this.examInputBasicInfoComponent.candidateCountComponent.candidateCount;
            this.sessionInfo.sessionName = this.examInputBasicInfoComponent.existingInformation.sessionName;
            this.prevNextComponent.hideNext();
            this.currentPage++;
         }
      } else if (this.currentPage === WizardPage.ExamTime && this.examDateTimeAndRegionComponent.validate()) {
         if (!this.inSubmission) {
            this.inSubmission = true;
            this.transferForm();
            this.spinnerService.show();
            this.prevNextComponent.hideNext();
            this.examSessionService
               .createExamSessionAndGetAccessCode(this.sessionInfo)
               .subscribe(data => {
                  this.spinnerService.hide();
                  this.sessionInfo.accessCode = data.accessCode;
                  this.currentPage++;
                  this.prevNextComponent.hideNext();
                  this.prevNextComponent.hidePrevious();
               },
                  error => {
                     this.spinnerService.hide();
                     this.prevNextComponent.displayNext();
                     this.reportError(error.status, error.error)
                     this.inSubmission = false;
                  }
               );
         }
      } else if (this.currentPage === WizardPage.Confirmation) {
         this.submitWizard.emit();
         this.prvSessionName.emit('');
      }
   }

   getTestCenter(testCenterId: number): ITestCenter | undefined {
      return this.testCenters.find(tc => tc.CustomerID == testCenterId);
   }

   reportError(status: number, detail: any): void {

      if (status == 400) {
         this.submissionError = $localize`Exams must be scheduled at least ${detail} minutes in advance.`;
      }
      else {
         const outageStart: string = formatDate(detail.outageStartTime+'Z', 'short', this.locale);
         const outageEnd: string = formatDate(detail.outageEndTime+'Z', 'short', this.locale);

         this.submissionError = $localize`A system outage is scheduled between ${outageStart} and ${outageEnd}. Please select a different time.`;
      }
   }



   onEmailSent() {
      this.prevNextComponent.displayNext();
   }

   onSelectExam(examSeriesCode: string) {
      this.sessionInfo.examId = examSeriesCode;
      this.currentPage++;

      if (this.sessionInfo.examDateTime && this.sessionInfo.time && this.sessionInfo.timeZoneId) {
         this.prevNextComponent.displayNext();
      }
   }

   onSelectLanguage(languageCode: string) {
      this.sessionInfo.language = languageCode;
   }

   onFormCompleted() {
      this.prevNextComponent.displayNext();
      this.transferForm();
   }

   transferForm() {
      if (this.examDateTimeAndRegionComponent) {
         const datepipe: DatePipe = new DatePipe('en-US')
         this.sessionInfo.examDateTime = <string>datepipe.transform(this.examDateTimeAndRegionComponent.dateSelectionComponent.dateForm.value.date, 'dd-MMM-YYYY');
         this.sessionInfo.time = this.examDateTimeAndRegionComponent.timeSelectionComponent.timeForm.value.time;
         this.sessionInfo.timeZoneId = this.examDateTimeAndRegionComponent.timeZoneSelectionComponent.timeZoneForm.value.timeZoneId;
      }
   }
}
