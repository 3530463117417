import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IFullExamSession } from '../../../models/IFullExamSession';
import { ExamSessionService } from '../../../services/exam-session.service';
import { IOption } from "../../../Controls/ctp-dropdown/ctp-dropdown.component";
import { AuthenticationService } from '../../../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { YesNoMessageBoxComponent } from '../../../Controls/yes-no-message-box/yes-no-message-box.component';
import { MessageBoxResult } from '../../../Controls/MessageBoxResult';
import { IProctor, ITestCenter } from "../../../models/IProctorInfo";
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ExamService } from "../../../services/exam.service";
import { SpinnerService } from 'src/app/services/spinner.service';
import { TimeZoneOffsetService } from '../../../services/time-zone-offset.service';
import { TranslationService } from "../../../services/translation.service";

@Component({
   selector: 'app-session-table',
   templateUrl: './session-table.component.html',
   styleUrls: ['./session-table.component.scss']
})
export class SessionTableComponent implements OnInit {
   @Output() onStartSessionClick = new EventEmitter();
   @Output() onSendConfirmationEmailClick = new EventEmitter();
   @Output() onSessionDetailsClick = new EventEmitter();
   @Output() onCancelSessionClick = new EventEmitter<string>();
   @Output() onSessionsInProgressMsg = new EventEmitter<string>();

   proctor: IProctor | undefined;
   testCenters: ITestCenter[] = [];
   localTime = new Date();
   sessionMsg: boolean = false;
   private proctorRoleKey = 3;

   public ExamSessions: IFullExamSession[] = [];
   public options: IOption[] = [
      { optionNum: 1, text: $localize`Send Confirmation Email` },
      { optionNum: 2, text: $localize`View Details` },
      { optionNum: 3, text: $localize`Cancel` }
   ];

   constructor(
      private _examSessionService: ExamSessionService,
      private _authenticationService: AuthenticationService,
      public _examService: ExamService,
      private _dialog: MatDialog,
      private spinnerService: SpinnerService,
      private _timeZoneOffsetService: TimeZoneOffsetService,
      public _translationService: TranslationService) { }

   ngOnInit(): void {
      this._authenticationService.ProctorSource.subscribe(x => {
         this.proctor = x;
         this.testCenters = x.testCenters;
         this.getSessions();
      });
   }

   getSessions() {
      this._examSessionService
         .getExamSessions()
         .subscribe(async response => {
            this.ExamSessions = response;
            var sessionsInProgress = this.ExamSessions.filter(s => s.examState.toLowerCase() == 'started');
            if (sessionsInProgress.length > 0) {
               this.onSessionsInProgressMsg.emit($localize`You have active exam session(s). Please return to your session(s).`);
            }
            this.setExamDetails();
            this.sessionMsg = true;
         }, error => {
            this.spinnerHide();

         });
   }

   private async setExamDetails() {
      await this._examService.precacheExams(this.ExamSessions.map(x => x.languageCode));
      await Promise.all(
         this.ExamSessions.map(async (x: IFullExamSession) => {
            const exam = await this._examService.getExam(x.examSeriesCode, x.languageCode);
            x.examName = exam ? exam.ProductName : "";
      }));
   }

   startSession(index: number) {
      this.onStartSessionClick.emit(this.ExamSessions[index]);
   }

   optionClicked($event: number, index: number) {
      switch ($event) {
         case 1:
            this.onSendConfirmationEmailClick.emit(this.ExamSessions[index]);
            break;
         case 2:
            this.onSessionDetailsClick.emit(this.ExamSessions[index]);
            break;
         case 3:
            this.handleCancelExamSessionClicked(this.ExamSessions[index]);
            break;
      }
   }

   private handleCancelExamSessionClicked(examSession: IFullExamSession) {
      const dlgRef = this._dialog.open(YesNoMessageBoxComponent, {
         height: '14rem', width: '28rem', data: $localize`Are you sure you want to cancel your exam session?`
      });
      dlgRef.afterClosed().subscribe(result => {
         if (result == MessageBoxResult.yes) {
            this._examSessionService.cancelExamSession(examSession.examSessionId, examSession.testingCenterId)
               .pipe(
                  catchError(this.handleCancelError)
               )
               .subscribe((error) => {
                  console.log('Error text: ' + error);
                  this.getSessions();
               });
         }
      });
   }

   private handleCancelError = (errorResponse: HttpErrorResponse): Observable<never> => {
      if (errorResponse.status === 0) {// Client-side or network error.
         const error = `Unexpected error: ${errorResponse.error}`;
         this.onCancelSessionClick.emit(error);
         return throwError(error);
      }
      else {// Back end error.
         const error = this.composeCancelErrorText(errorResponse.error.error);
         this.onCancelSessionClick.emit(error);
         return throwError(error);
      }
   }

   public composeCancelErrorText(error: string): string {
      if (error === 'started')
         return CancelErrorText.Started;
      if (error === 'tooLate')
         return CancelErrorText.TooLate;
      if (error === 'missing')
         return CancelErrorText.Missing;
      if (error === 'noPermission')
         return CancelErrorText.NoPermission;
      return 'Unknown error.';
   }

   getTimeZoneOffsetText(examSession: IFullExamSession): string {
      return this._timeZoneOffsetService.formatOffset(examSession.timeZoneOffset);
   }

   canStartSession(examSession: IFullExamSession) {
      if (this.proctor) {
         var proctorCenter = this.proctor.testCenters.find(x => {
            return x.CustomerID === examSession.testingCenterId && x.RoleID === this.proctorRoleKey;
         });
         return !!proctorCenter;
      }
      return false;
   }
   spinnerHide() {
      this.spinnerService.hide();
   }
}

export class CancelErrorText {
   static readonly Started: string = $localize`The session has already started and cannot be canceled.`;
   static readonly TooLate: string = $localize`It is past the deadline for canceling this session.`;
   static readonly Missing: string = $localize`The session does not exist.`;
   static readonly NoPermission: string = $localize`You do not have permission to cancel this session.`;
}
