import { Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IReleaseVmInput } from '../models/IReleaseVmInput';
import { IRestartVmInput } from '../models/IRestartVmInput';
import { IResponseResult } from '../models/IResponseResult';
import { AuthenticationService } from './authentication.service';
import { BASE_URL } from '../injection-tokens/base-url.token';

@Injectable({
   providedIn: 'root'
})
export class SessionInfoService {

   constructor(private httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, private _authenticationService: AuthenticationService) { }

   releaseVM(releaseVmInput: IReleaseVmInput): Observable<IResponseResult> {
      return this.httpClient
         .post<IResponseResult>(
            this.baseUrl + 'api/SessionInfo/releaseVM',
            releaseVmInput);
   }

   restartVM(restartVmInput: IRestartVmInput): Observable<IResponseResult> {

      return this.httpClient
         .post<IResponseResult>(
            this.baseUrl + 'api/SessionInfo/restartVM',
            restartVmInput);
   }
}
