import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, flatMap, map } from 'rxjs/operators';
import { IExamSessionSettings } from '../models/IExamSessionSettings';
import { IFullExamSession } from '../models/IFullExamSession';
import { SystemErrorType } from '../models/SystemErrorType';
import { AuthenticationService } from '../services/authentication.service';
import { ExamSessionService } from '../services/exam-session.service';
import { SpinnerService } from '../services/spinner.service';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root'
})

export class StartExamGuard implements CanActivate {

  /**
   * Key of proctor role
   */
  private proctorRoleKey = 3;
  /**
   * Access code
   */
  private accessCode: string = '';

   constructor(public authService: AuthenticationService, public router: Router, public spinnerService: SpinnerService, private examSessionService: ExamSessionService) {
      
   }


   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

      if (this.authService.Jwt && route.queryParams.accessCode) {
         this.accessCode = route.queryParams.accessCode;
         this.spinnerService.show();
         if (!this.authService.Proctor) {
            return this.authService.FetchProctor().pipe(
              flatMap((res) => { return this.startExam(this.accessCode); }),
               catchError((e, caught) => {
                  this.authService.Logout();
                  this.router.navigate(['login'], { queryParams: route.queryParams });
                  return of(false);
               })
            )
         }
         return this.authService.ProctorSource.pipe(() => this.startExam(this.accessCode));
      } else {
         // Send to Login to verify and login page logic should send ba back here after.
         this.router.navigate(['login'], { queryParams: route.queryParams });
         return of(false);
      }
   }

   /**
    * Starts the exam after verifying examSessionSettings and proctor.
    * Routes proctor to error pages in event of error
    * @param accessCode A unique code for an exam session
    * @returns observable of true when successfully started and false otherwise
    */
   private startExam(accessCode: string): Observable<boolean> {
      return this.examSessionService.getExamSessionSettings().pipe(
         // Verifies proctor and returns examSession when all conditions to start exam is met
         flatMap(examSessionSettings => {
            return this.examSessionService.getExamSessionByAccessCode(accessCode).pipe(map(examSession => {
               if (examSession) {
                  this.spinnerService.hide();
                  if (this.isWithinTime(examSession, examSessionSettings) && this.isProctorOfTestCenter(examSession?.testingCenterId)) {
                     return examSession;
                  }
                  return null;
               } else {
                  this.router.navigate(['/system/error'], { queryParams: { error: SystemErrorType.WRONG_EXAM_CODE } });
                  return null;
               }
            }));
         }),
         // Starts the examSession recieved from flatMap above
        flatMap(examSessionToStart => {
            if (examSessionToStart) {
              return this.startSessionInLabManager(examSessionToStart);
            }
            return of(false);
         }),
         catchError(error => {
            this.spinnerService.hide()
            this.router.navigate(['/system/error'], { queryParams: { error: SystemErrorType.UNKNOWN } });
            return of(false);
         })
      );
   }

   /**
    *
    * Proctor must have proctor role and must be associated to testing center
    * @param testingCenterId The testingCenter of exam session
    * @returns True when proctor is a proctor of the testing center;
    */
   private isProctorOfTestCenter(testingCenterId: number): boolean {
      if (this.authService.Proctor) {
         var proctorCenter = this.authService.Proctor.testCenters.find(x => {
            return x.CustomerID === testingCenterId && x.RoleID === this.proctorRoleKey;
         });
        if (proctorCenter) {
          return true;
        } else {
          this.router.navigate(['/system/error'], { queryParams: { error: SystemErrorType.WRONG_TEST_CENTER } });
          return false;
        }
      }
      this.router.navigate(['/system/error'], { queryParams: { error: SystemErrorType.UNKNOWN } });
      return false;
   }

   /**
    * Verifies if the exam session is within the window to start exam session
    * Routes Proctor to the Lobby when they are early.
    * @param examSession
    * @param examSessionSettings
    * @returns 
    */
   private isWithinTime(examSession: IFullExamSession, examSessionSettings: IExamSessionSettings): boolean {
      let examStartTime = new Date(examSession.startTime);
      let earliestStartTime = new Date(examStartTime);
      let latestStartTime = new Date(examStartTime);
      let maximumExamMinutes = new Date(examStartTime);
      earliestStartTime.setMinutes(earliestStartTime.getMinutes() - examSessionSettings.earlyStartMinutes);
      latestStartTime.setMinutes(latestStartTime.getMinutes() + examSessionSettings.lateStartMinutes);
      maximumExamMinutes.setMinutes(maximumExamMinutes.getMinutes() + examSessionSettings.maximumExamMinutes);

      let currentTime = new Date();
      currentTime = this.convertToUTCTime(currentTime);

      if (currentTime.getTime() < earliestStartTime.getTime()) {
         this.router.navigate(['/system/error'], { queryParams: { error: SystemErrorType.TOO_EARLY_TO_START, accessCode: this.accessCode } });
         return false;
      } else if ((currentTime.getTime() > latestStartTime.getTime() && examSession.examState.toLowerCase().trim() != 'started') || (currentTime.getTime() > maximumExamMinutes.getTime())) {
         // TODO: What if Proctor tries to start old exam from URL?
         this.router.navigate(['/system/error'], { queryParams: { error: SystemErrorType.EXPIRED_ACCESS_CODE, accessCode: this.accessCode } });
         return false;
      } else {
         return true;
      }
   }

   /**
    * Sets the miliseconds of date to the utc miliseconds. (Timezone offset cannot be changed)
    * @param date The date to convert
    * @returns A date that has its miliseconds (date.getTime()) converted to utc
    */
   private convertToUTCTime(date: Date) {
      var utcDateTime = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      return utcDateTime;
   }

   /**
    * Starts the examSession in Lab Manager.
    * Routes proctor to System Outage page when not enough machines available or to Unknown for other errors.
    * @param examSession
    */
   private startSessionInLabManager(examSession: IFullExamSession): Observable<boolean> {
      this.spinnerService.show();
      return this.examSessionService.startExamSession(examSession.examSessionId, examSession.testingCenterId).pipe(
         map(x => {
            this.spinnerService.hide();
            return true;
         }),
         catchError(error => {
            this.spinnerService.hide();
            if (error && error.status === 409 && !error.error.enoughMachines) {
               this.router.navigate(['/system/error'], { queryParams: { error: SystemErrorType.SYSTEM_OUTAGE } });
            } else {
               this.router.navigate(['/system/error'], { queryParams: { error: SystemErrorType.UNKNOWN } });
            }
            return of(false);
         })
      );
   }
}
