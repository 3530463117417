import {Component, EventEmitter, Output} from '@angular/core';
import {IProgram} from "../../../../../../../ProctorApp2.Logic/Models/i.program";

@Component({
   selector: 'app-program-filter',
   templateUrl: './program-filter.component.html',
   styleUrls: ['./program-filter.component.scss']
})
export class ProgramFilterComponent {
   filteredProgramId = 0;
   @Output() onChangeProgram = new EventEmitter<number>();
   programs: IProgram[] = [];
   error: string = '';

   constructor() { }

   selectionChange() {
      this.onChangeProgram.emit(this.filteredProgramId);
   }
}
