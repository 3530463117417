import { Component, OnInit } from '@angular/core';
import { SystemErrorType } from '../../models/SystemErrorType';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamSessionService } from '../../services/exam-session.service';
import { IExamSessionSettings } from '../../models/IExamSessionSettings';
import { AppConstants } from '../../constants/AppConstants';

/**
 * Page for displaying any start session errors.
 */
@Component({
   selector: 'app-start-session-error',
   templateUrl: './system-error.component.html',
   styleUrls: ['./system-error.component.scss']
})
export class SystemErrorComponent implements OnInit {
   public errorType: SystemErrorType | undefined;
   public errorTypeEnum = SystemErrorType;
   public examSessionSettings!: IExamSessionSettings;
   accessCode = "";
   supportURL = AppConstants.SupportURL;
   /**
    * Empty constuctor.
    *
    * @param activatedRoute
    */
   public constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router, private examSessionService: ExamSessionService
   ) {
      this.examSessionService.getExamSessionSettings().subscribe(sessionSettings => {
         this.examSessionSettings = sessionSettings
      });
   }

   /**
    * Initializes the page.
    */
   public ngOnInit(): void {

      this.accessCode = this.activatedRoute.snapshot.queryParams.accessCode

      this.errorType = this.activatedRoute.snapshot.queryParams.error ?
         this.activatedRoute.snapshot.queryParams.error :
         SystemErrorType.UNKNOWN;
   }

   startSession() {
      this.router.navigate(['/exams-view'], { queryParams: { accessCode: this.accessCode } });
   }

   public returnToDashboard(): void {
      this.router.navigate(['/']);
   }
}
