import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IExamSessionInfo } from '../models/IExamSessionInfo';
import { IExamSessionInput } from '../models/IExamSessionInput';
import { Observable } from 'rxjs';
import { IFullExamSession } from '../models/IFullExamSession';
import { AuthenticationService } from './authentication.service';
import { IExamSessionSettings } from "../models/IExamSessionSettings";
import { text } from '@fortawesome/fontawesome-svg-core';
import { BASE_URL } from '../injection-tokens/base-url.token';


@Injectable({
   providedIn: 'root'
})
export class ExamSessionService {

   constructor(
      private _httpClient: HttpClient,
      @Inject(BASE_URL) private _baseUrl: string,
      private _authenticationService: AuthenticationService) { }

   createExamSessionAndGetAccessCode(examSessionInput: IExamSessionInput): Observable<IExamSessionInfo> {
      return this._httpClient
         .post<IExamSessionInfo>(this._baseUrl + 'api/ExamSessions', examSessionInput);
   }

   getExamSessions(): Observable<IFullExamSession[]> {
      return this._httpClient
         .get<IFullExamSession[]>(this._baseUrl + `api/ExamSessions/`);
   }

   getExamSessionByAccessCode(accesscode: string): Observable<IFullExamSession> {
      return this._httpClient
         .get<IFullExamSession>(
            this._baseUrl + `api/ExamSessions/session/${accesscode}`);
   }

   getExamSessionSettings(): Observable<IExamSessionSettings> {
      return this._httpClient
         .get<IExamSessionSettings>(this._baseUrl + 'api/ExamSessions/settings');
   }

   startExamSession(examSessionId: number, testingCenterId: number): Observable<any> {
      return this._httpClient
         .post(this._baseUrl + `api/ExamSessions/${examSessionId}/start?testCenterId=${testingCenterId}`, {});
   }

   cancelExamSession(examSessionId: number, testingCenterId: number): Observable<object> {
      return this._httpClient
         .delete(`${this._baseUrl}api/ExamSessions/${examSessionId}?testCenterId=${testingCenterId}`);
   }
}
