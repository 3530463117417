<div class="session-details-content">
   <div>
      <h1 i18n>Exam Session Details</h1>

      <div class="session-details-main-content">
         <div class="flex-col">
            <h2 i18n>Session Name</h2>
            <p class="session-value">{{sessionInfo.sessionName}}</p>

            <h2 i18n>Access Code</h2>
            <p class="session-value">{{sessionInfo.accessCode}}</p>

            <h2 i18n>Exam Name</h2>
            <p class="session-value">{{sessionInfo.examName}}</p>

            <h2 i18n>Language</h2>
            <p class="session-value">{{sessionInfo.languageName}}</p>

            <h2 i18n>Number of Candidates</h2>
            <p class="session-value">{{sessionInfo.candidateCount}}</p>
         </div>

         <div class="flex-col">
            <h2 i18n>Specified Date*</h2>
            <p class="session-value">{{sessionInfo.displayStartTime | date: 'longDate'}}</p>

            <h2 i18n>Specified Time*</h2>
            <div class="session-value time-value-container">
               <div class="time-zone-container" style="word-break: break-word; text-align-last: center;">
                  <input type="time" class="start-time" value="{{sessionInfo.displayStartTime | date : 'HH:mm'}}" readonly />
                  <span class="time-zone-text">{{_translationService.getTimeZone(sessionInfo.timeZoneName)}}</span>
               </div>
            </div>

            <h2 i18n>Program Name</h2>
            <p class="session-value">{{sessionInfo.programName}}</p>

            <h2 i18n>Test Center</h2>
            <p class="session-value">{{sessionInfo.testingCenterName}}</p>
         </div>
      </div>
   </div>
</div>
<app-prev-next (clickPrevious)="this.previous.emit()" [nextHidden]="'hidden'"></app-prev-next>
