import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
   selector: 'app-exam-search',
   templateUrl: './exam-search.component.html',
   styleUrls: ['./exam-search.component.scss']
})
export class ExamSearchComponent implements OnInit {
   @Output() examSearch = new EventEmitter<string>();
   searchValue = '';
   placeHolder = $localize`Search`;

   constructor() { }

   ngOnInit(): void {
   }

   search() {
      this.examSearch.emit(this.searchValue)
   }

   clearSearch() {
      this.searchValue = '';
      this.search();
   }
}
