<div class="dialog">
   <div class="dialog-section dialog-header">
      <h2 i18n>Edit Email Address (This session only)</h2>
      <div class="close" (click)="closeDialog()" (pointerdown)="closeDialog()">
         <p i18n>Close</p>
         <fa-icon [icon]="timesIcon" class="x"></fa-icon>
      </div>
   </div>
   <div class="dialog-section dialog-body">
      <h2 i18n>Current Email Address</h2>
      <p>{{email}}</p>

      <div class="div-input">
         <h2><label for="email" i18n>New Email Address<span class="required">*</span></label></h2>
         <input id="email" [(ngModel)]="local_data.email" (blur)="emailOnBlur()" [ngClass]="showEmailReqValidation ? 'input-error' : 'input-no-error'" />
         <div *ngIf="showEmailReqValidation" class="error after-error-margin">
            <span class="cross-sign"> &#10006; </span>
            <span i18n>Email must be valid.</span>
         </div>
      </div>

      <div class="div-input">
         <h2><label for="emailConfirm" i18n>Confirm New Email Address<span class="required">*</span></label></h2>
         <input id="emailConfirm" [(ngModel)]="local_data.emailConfirm" (blur)="cnfEmailOnBlur()" [ngClass]="showCnfEmailReqValidation || showEmailMatchValidation ? 'input-error' : 'input-no-error'" />
         <div *ngIf="showCnfEmailReqValidation" class="error">
            <span class="cross-sign"> &#10006; </span>
            <span i18n>Email must be valid.</span>
         </div>
         <div *ngIf="showEmailMatchValidation" class="error">
            <span class="cross-sign"> &#10006; </span>
            <span i18n> Emails must match.</span>
         </div>
      </div>

   </div>
   <div class="dialog-section-no-bottom dialog-footer">
      <ctp-button [style]="'secondary'" (click)="closeDialog()" (pointerdown)="closeDialog()" i18n>Cancel</ctp-button>
      <ctp-button class="mat-button" [style]="'primary'" (click)="submit()" i18n>Submit</ctp-button>
   </div>
</div>
