import { Inject, Injectable } from '@angular/core';
import { BASE_HREF } from '../injection-tokens/base-href.token';
import { environment } from '../../environments/environment';

/**
 * Service for interacting with the global window object.
 */
@Injectable({
  providedIn: 'root'
})
export class WindowService {

  /**
   * Empty constructor.
   *
   * @param baseHref
   */
  public constructor(
    @Inject(BASE_HREF) private baseHref: string
  ) { }

  /**
   * Opens a new window and appends the basehref to it if need be.
   *
   * @param url to open.
   * @param target window target. Defaults to _blank.
   */
  public open(url: string, target?: string): void {
    let updatedUrl = url;
    if (environment.usesLocaleInUrl) {
      if (updatedUrl.startsWith("/")) updatedUrl = updatedUrl.replace("/", "");
      updatedUrl = this.baseHref.concat(updatedUrl);
    }
    window.open(updatedUrl,target ? target : '_blank');
  }
}
