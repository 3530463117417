<div class="exam-selection-container">
   <app-alert state="{{status}}" [ngClass]="alertHidden">
      {{message}}
   </app-alert>
      <h1 i18n>Select Exam</h1>
      <h2 i18n>Please ensure your License is activated and your inventory is sufficient for this exam session.</h2>
      <div class="flex-row">
         <app-exam-search class="filter" (examSearch)="onExamSearch($event)"></app-exam-search>
         <app-language-filter class="filter" (onChangeLanguage)="changeLanguage($event)" [languageCode]="languageCode"></app-language-filter>
         <app-program-filter class="filter" (onChangeProgram)="changeProgram($event)"></app-program-filter>
      </div>
      <app-exam-table [languageCode]="languageCode" (selectExam)="onSelectExam($event)"></app-exam-table>
</div>
