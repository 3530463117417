import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-footer',
   templateUrl: './footer.component.html',
   styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

   private currentYear: number = new Date().getFullYear();
   copyright: string = $localize `Copyright \u00a9 1996-${(this.currentYear)} Pearson Education, Inc. or its affiliate(s). All rights reserved.`;

   constructor() { }

   ngOnInit(): void {
   }

}
