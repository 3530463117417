import { Inject, Injectable } from '@angular/core';
import { SignalRHub } from "./SignalRHub";
import { BASE_URL } from '../injection-tokens/base-url.token';

@Injectable({
   providedIn: 'root'
})
export class SignalRService {

   constructor(private _signalRHub: SignalRHub, @Inject(BASE_URL) private _baseUrl: string) { }

   public startConnection(accessCode: string): Promise<void> {
      this._signalRHub.initialize(this._baseUrl + 'ProctorHub');
      return this.start(accessCode);
   }

   private start(accessCode: string): Promise<void> {
      return this._signalRHub.start()
         .then(() => {
            this.subscribe(accessCode);
         })
         .catch((err: any) => console.log('SignalR connection error:' + err));
   }

   public addCandidateLoggedInListener(listener: (accessCode: string, symlink: string, joinUrl: string, candidateId: string, candidateFirstName: string, candidateLastName: string, assignedToCandidateTime: string) => void): void {
      this._signalRHub.on('machineAdded', (accessCode: string, symlink: string, joinUrl: string, candidateId: string, candidateFirstName: string, candidateLastName: string, assignedToCandidateTime: string) => {
         listener(accessCode, symlink, joinUrl, candidateId, candidateFirstName, candidateLastName, assignedToCandidateTime);
      })
   }

   public addCompleteExamListener(listener: (symlink: string) => void): void {
      this._signalRHub.on('completeExam', (symlink: string) => {
         listener(symlink);
      })
   }

   public addCandidateAutoRefreshListner(listener: (symlink: string) => void): void {
      this._signalRHub.on('candidateAutoRefresh', (symlink: string) => {
         listener(symlink);
      })
   }

   private subscribe(accessCode: string): Promise<void> {
      return this._signalRHub.invoke("Subscribe", accessCode);
   }
}
