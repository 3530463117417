import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';


@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss']
})
export class DateTimeComponent implements OnInit {
   infoCircle = faInfoCircle;

   constructor() { }

   dateTimeControl: FormControl = new FormControl('');

   get dateTimeValue(): string {
      return this.dateTimeControl.value;
   }

  ngOnInit(): void {
  }

}
