<div class="test-center-selection-container">
   <label for="center-selection" class="test-center" [class.error]="hasError" i18n>Testing center *
   <fa-icon [icon]="infoCircle" i18n-matTooltip matTooltip="Displays list of active centers with which you're associated."
            matTooltipPosition="right">
   </fa-icon>
</label>
   <br>
   <mat-form-field id="center-selection" appearance="outline" class="select-one">
      <mat-label i18n>Select one</mat-label>
      <mat-select [(ngModel)]="testCenterId" (focus)="validateInline()" (focusout)="validateInline()" required>
         <ng-container *ngFor="let tc of testCenters">
            <mat-option *ngIf="tc.IsActive" value="{{tc.CustomerID}}">{{tc.CustomerName}}</mat-option>
         </ng-container>
      </mat-select>
   </mat-form-field>
</div>
