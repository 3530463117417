<div class="candidate-count-selection-container">
   <label for="candidate-count" class="form-label"  [class.error]="hasError"><span i18n>Number of Candidates *</span>
      <fa-icon
         [icon]="infoCircle"
         i18n-matTooltip matTooltip="Enter the number of Candidates expected for this exam session, up to {{maxValue}}."
         matTooltipPosition="right">
      </fa-icon>
   </label>
   <br>

   <mat-form-field id="candidate-count" class="text-field" appearance="outline" >
      <input type="number" matInput (focus)="highlightInput($event)" [(ngModel)]="candidateCount" (focusout)="validateInline()" required min="1" max="50">
   </mat-form-field>
</div>
