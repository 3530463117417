import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeZoneOffsetService {

   constructor() { }

   formatOffset(offset: number): string {
      if (offset === 0)
         return '';
      if (offset > 0)
         return '+' + offset.toString();
      return offset.toString();
   }
}
