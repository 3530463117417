import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";
import { SpinnerService } from "../services/spinner.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
   private redirectCodes: number[] = [401, 403];

   constructor(private router: Router, private _authService: AuthenticationService, private _spinner: SpinnerService, ) { }

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (this._authService.Jwt) {
         request = request.clone({
            setHeaders: {
               Authorization: `Bearer ${this._authService.Jwt}`
            }
         });
      }

      return next.handle(request).pipe(
         tap(() => { },
         (err: any) => {
            if (err instanceof HttpErrorResponse) {
               if (this.redirectCodes.indexOf(err.status) === -1) {
                  return;
               }
               this._authService.Logout();
               this._spinner.hide();
               this.router.navigate(['login']);
            }
         }));
   }
}
