import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import ILogin from '../../../models/ILogin';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
   @Input() loading = false;
   @Output() submit = new EventEmitter<ILogin>();

   submitted = false;

   loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
   });

   constructor(private formBuilder: FormBuilder) {
   }

   ngOnInit(): void {
   }

   // convenience getter for easy access to form fields
   get f() { return this.loginForm.controls; }

   onSubmit() {
      this.submitted = true;
      if (this.loginForm.valid) {
         this.submit.emit(this.loginForm.value);
      }
   }

   showErrors(key: string): boolean {
      var control = this.loginForm.get(key);
      if (control) {
         return this.submitted && control.invalid;
      } else {
         return false;
      }
   }
}
