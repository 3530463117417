import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateTimeService } from '../../services/date-time.service';
import { TimeZoneSelectionComponent } from "./time-zone-selection/time-zone-selection.component";
import { DateSelectionComponent } from "./date-selection/date-selection.component";
import { TimeSelectionComponent } from "./time-selection/time-selection.component";
import { IExamSessionInput } from "../../models/IExamSessionInput";
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil, takeWhile, tap } from 'rxjs/operators';
import { TranslationService } from "../../services/translation.service";

@Component({
   selector: 'app-exam-date-time-and-region',
   templateUrl: './exam-date-time-and-region.component.html',
   styleUrls: ['./exam-date-time-and-region.component.scss']
})
export class ExamDateTimeAndRegionComponent implements OnInit {
   isNextButtonHidden = true;
   errorMsg = $localize`One or more errors have been found in the field(s) indicated below. All errors must be corrected.`;

   @Output() formCompleted = new EventEmitter();

   @Input() submissionError: string = '';

   @Output() nextClick = new EventEmitter();
   @Input() existingInformation!: IExamSessionInput;
   @ViewChild(TimeZoneSelectionComponent) timeZoneSelectionComponent = new TimeZoneSelectionComponent(this._translationService, this.timeZoneRegionService);
   @ViewChild(DateSelectionComponent) dateSelectionComponent = new DateSelectionComponent();
   @ViewChild(TimeSelectionComponent) timeSelectionComponent = new TimeSelectionComponent();
   @Input() calenderStartDate: Date | undefined;
   @Input() windowsCurrentTimeZoneId:string='';

   public examDateTimeRegionForm = new FormGroup({
      timeZoneSelection: new FormControl(""),
      dateSelection: new FormControl(""),
      timeSelection: new FormControl("")
   });
   get hasError(): boolean {
      if (!this.dateSelectionComponent || !this.timeSelectionComponent || !this.timeZoneSelectionComponent) {
         return false;
      }
      return !(this.dateSelectionComponent.valid &&
         this.timeSelectionComponent.valid &&
         this.timeZoneSelectionComponent.valid);
   }


   constructor(private timeZoneRegionService: DateTimeService,
      private _translationService: TranslationService) { }

   ngOnInit(): void {
      this.getTimeZonesAndRegions();
      this.initFormChangesSubscription();
   }

   getTimeZonesAndRegions() {
      this.timeZoneRegionService.getTimeZone().subscribe((data: any) => {

         if (data != null) {
            this.timeZoneSelectionComponent.timeZones = data.timeZones;

            var currentTimezoneId = data.timeZones.find((a: any) => a.windowsTimeZoneId == this.windowsCurrentTimeZoneId)?.timeZoneId as string
            if (!this.existingInformation.timeZoneId && currentTimezoneId != undefined) {
               this.timeZoneSelectionComponent.timeZoneForm.controls['timeZoneId'].setValue(currentTimezoneId);
            }

         }
      });
   }

   /**
    * Subscribes only when existingInformation is not yet completed
    * Subscribes to changes of form until form is completed.
    */
   initFormChangesSubscription(): void {
      if (this.existingInformation.regionId && this.existingInformation.examDateTime && this.existingInformation.time && this.existingInformation.timeZoneId) {
         this.formCompleted.emit();
         this.isNextButtonHidden = false;
      } else {
         this.examDateTimeRegionForm.valueChanges
            .pipe(
               takeWhile(() => this.isNextButtonHidden),
               tap((values) => {
                  if (values.timeZoneSelection)
                     this.existingInformation.timeZoneId = values?.timeZoneSelection?.timeZoneId;

                  if (values.dateSelection)
                     this.existingInformation.examDateTime = values?.dateSelection?.date?.toString() ?? "";

                  if (values.timeSelection)
                     this.existingInformation.time = values?.timeSelection?.time ?? "";
               }))
            .subscribe((values) => {
               if (values.timeZoneSelection && values.dateSelection && values.timeSelection) {
                  this.formCompleted.emit();
                  this.isNextButtonHidden = false;
               }
            });
      }
   }

   validate(): boolean {
      return !this.hasError;
   }

   setCalenderStartDate(startDate: Date) {
      this.calenderStartDate = startDate;
   }
}
