import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MainContentComponent } from './main-content/main-content.component';
import { WizardComponent } from './wizard/wizard.component';
import { ExamsViewComponent } from './exams-view/exams-view.component';
import { CtpButtonComponent } from './Controls/ctp-button/ctp-button.component';
import { CandidateCountComponent } from './wizard/exam-input-basic-info/candidate-count/candidate-count.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageSelectionComponent } from './wizard/exam-input-basic-info/language-selection/language-selection.component';
import { LanguageFilterComponent } from './wizard/exam-selection/language-filter/language-filter.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { TestCenterSelectionComponent } from './wizard/exam-input-basic-info/test-center-selection/test-center-selection.component';
import { ExamSelectionComponent } from './wizard/exam-selection/exam-selection.component';
import { CandidateVmComponent } from './exams-view/candidate-session/candidate-vm/candidate-vm.component';
import { SessionInfoComponent } from './exams-view/candidate-session/session-info/session-info.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { DateTimeComponent } from './Controls/date-time/date-time.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderBodySeparatorComponent } from './header-body-separator/header-body-separator.component';
import { DashboardComponent } from './session-management/dashboard/dashboard.component';
import { SessionManagementPageComponent } from './pages/session-management-page/session-management-page.component';
import { ExamsViewPageComponent } from './pages/exams-view-page/exams-view-page.component';
import { SessionManagementComponent } from './session-management/session-management.component';
import { ExamDateTimeAndRegionComponent } from './wizard/exam-date-time-and-region/exam-date-time-and-region.component';
import { SessionConfirmationComponent } from './wizard/session-confirmation/session-confirmation.component';
import { EmailDialogComponent } from './wizard/session-confirmation/email-dialog/email-dialog.component';
import { AlertComponent } from './alert/alert.component';
import { SessionTableComponent } from './session-management/dashboard/session-table/session-table.component';
import { SessionDetailsComponent } from './session-management/session-details/session-details.component';
import { CtpDropdownComponent } from './Controls/ctp-dropdown/ctp-dropdown.component';
import { ExamInputBasicInfoComponent } from './wizard/exam-input-basic-info/exam-input-basic-info.component';
import { PrevNextComponent } from './Controls/prev-next/prev-next.component';
import { ChatnotificationComponent } from './chatnotification/chatnotification.component';
import { ChatBoxComponent } from './exams-view/candidate-session/chat-box/chat-box.component';
import { HelpfulresourcesComponent } from './session-management/dashboard/helpfulresources/helpfulresources.component';
import { NameSessionComponent } from './session-management/dashboard/name-session/name-session.component';
import { YesNoMessageBoxComponent } from './Controls/yes-no-message-box/yes-no-message-box.component';
import { ExamTableComponent } from './wizard/exam-selection/exam-table/exam-table.component';
import { ExamSearchComponent } from './wizard/exam-selection/exam-search/exam-search.component';
import {MatButtonModule} from "@angular/material/button";
import { ProgramFilterComponent } from './wizard/exam-selection/program-filter/program-filter.component';
import {CandidateSessionComponent} from "./exams-view/candidate-session/candidate-session.component";
import { TimeZoneSelectionComponent } from './wizard/exam-date-time-and-region/time-zone-selection/time-zone-selection.component';
import { DateSelectionComponent } from './wizard/exam-date-time-and-region/date-selection/date-selection.component';
import { TimeSelectionComponent } from './wizard/exam-date-time-and-region/time-selection/time-selection.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginComponent } from './components/login/login.component';
import { LoginFormComponent } from './components/login/login-form/login-form.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthenticationService } from './services/authentication.service';
import { EmailValidationBase, EmailValidationService } from './services/email-validation.service';
import { CtpTextDropdownComponent } from './Controls/ctp-text-dropdown/ctp-text-dropdown.component';
import { CtpDropdownBoxComponent } from './Controls/ctp-dropdown-box/ctp-dropdown-box.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { SystemErrorComponent } from './pages/start-session-error/system-error.component';
import { SurveyDialogComponent } from './Controls/survey-dialog/survey-dialog.component';
import { CandidateExamCompleteComponent } from './exams-view/candidate-session/candidate-exam-complete/candidate-exam-complete.component';
import { ConfigurationsService } from './services/configurations.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { CandidatesInExamComponent } from './candidates-in-exam/candidates-in-exam.component';
import { TechnicalSupportComponent } from './alert/technical-support/technical-support.component';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import {MatDatepickerModule} from "@angular/material/datepicker";
import { CandidateVmRestartMessageComponent } from './exams-view/candidate-session/candidate-vm-restart-message/candidate-vm-restart-message.component';

const configInitializer = (config: ConfigurationsService) => {
   return () => {
     return config.getConfigurationSettings();
   };
 };

@NgModule({
   declarations: [
      AppComponent,
      HeaderComponent,
      FooterComponent,
      MainContentComponent,
      WizardComponent,
      ExamsViewComponent,
      CtpButtonComponent,
      CandidateCountComponent,
      LanguageSelectionComponent,
      LanguageFilterComponent,
      TestCenterSelectionComponent,
      ExamSelectionComponent,
      CandidateSessionComponent,
      CandidateVmComponent,
      SessionInfoComponent,
      SpinnerComponent,
      DateTimeComponent,
      HeaderBodySeparatorComponent,
      DashboardComponent,
      SessionManagementPageComponent,
      ExamsViewPageComponent,
      SessionManagementComponent,
      ExamDateTimeAndRegionComponent,
      ChatnotificationComponent,
      SessionConfirmationComponent,
      AlertComponent,
      EmailDialogComponent,
      SessionTableComponent,
      SessionDetailsComponent,
      CtpDropdownComponent,
      ExamDateTimeAndRegionComponent,
      ExamInputBasicInfoComponent,
      PrevNextComponent,
      ChatBoxComponent,
      HelpfulresourcesComponent,
      NameSessionComponent,
      YesNoMessageBoxComponent,
      ExamTableComponent,
      ExamSearchComponent,
      ProgramFilterComponent,
      TimeZoneSelectionComponent,
      DateSelectionComponent,
      TimeSelectionComponent,
      LoginPageComponent,
      LoginComponent,
      LoginFormComponent,
      CtpTextDropdownComponent,
      CtpDropdownBoxComponent,
      PlaceholderComponent,
      SurveyDialogComponent,
      SystemErrorComponent,
      CandidateExamCompleteComponent,
      CandidatesInExamComponent,
      TechnicalSupportComponent,
      AutofocusDirective,
      CandidateVmRestartMessageComponent
   ],
   imports: [
      BrowserModule,
      FormsModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      HttpClientModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatSliderModule,
      MatTooltipModule,
      FontAwesomeModule,
      MatDialogModule,
      MatButtonModule,
      MatDatepickerModule
   ],
   providers: [
      AuthGuard,
      AuthenticationService,
      ConfigurationsService,
      { provide: EmailValidationBase, useClass: EmailValidationService },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      { provide: APP_INITIALIZER,useFactory: configInitializer,multi: true,deps: [ConfigurationsService] }
   ],
   bootstrap: [AppComponent]
})
export class AppModule { }
