<div class="app-footer">
   <div id="footer-wrapper">
      <div role="contentinfo">
         <div class="copyright">
            <div class="copyrightTextHolder">
               <div class="Support-img">
                  <div class="tooltip">
                     <span class="tooltip-text" i18n>Test Candidate Support</span>
                     <a id="c_Footer_c_RunDriverDiagnosticsFooter" href="https://certiport.pearsonvue.com/Support.aspx" target="_blank">
                        <img src="/images/footer-driverdiagnostics.png" i18n-title title="Test Candidate Support" i18n-alt alt="Test Candidate Support">
                     </a>
                  </div>
               </div>
               <div class="copyright-container">
                  <span id="c_Footer_c_CopyrightLbl">{{copyright}}</span><br>
                  <a id="c_Footer_c_TermsLink" href="https://wsr.pearsonvue.com//legal/appterms/" style="color:white" i18n-title title="Terms" target="_blank" i18n>Terms</a>&nbsp;|&nbsp;
                  <a id="c_Footer_c_PrivacyLink" href="https://wsr.pearsonvue.com//legal/appprivacy/" style="color:white" i18n-title title="Privacy" target="_blank" i18n>Privacy</a>&nbsp;|&nbsp;
                  <a id="c_Footer_c_ContactLink" href="https://certiport.pearsonvue.com/Contact-Us.aspx" i18n-title title="Contact" style="color:white" target="_blank" i18n>Contact</a>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
