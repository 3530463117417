import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimeZoneRegion } from '../models/ITimeZone';
import { AuthenticationService } from './authentication.service';
import { BASE_URL } from '../injection-tokens/base-url.token';

@Injectable({
   providedIn: 'root'
})
export class DateTimeService {

   constructor(private httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, private _authenticationService: AuthenticationService) { }

   getTimeZone(): Observable<ITimeZoneRegion> {
      const url = this.baseUrl + 'api/TimeZoneRegion/GetTimeZonesAndRegion';
      return this.httpClient.get<ITimeZoneRegion>(url);
   }

   getCurrentDateByWindowsTimeZoneId(windowsTimeZoneId: string): Observable<Date> {
      return this.httpClient.get<Date>(
         this.baseUrl + `api/TimeZoneRegion/getCurrentDateByWindowsTimeZoneId/${windowsTimeZoneId}`);
   }
   
   getWindowsCurrentTimezone(browserTimezone: string): Observable<any> {
      const url = this.baseUrl + `api/TimeZoneRegion/GetWindowsCurrentTimezone?browserTimezone=${browserTimezone}`;
      return this.httpClient.get(url, { responseType: 'text' })
   }
  
}

