<div class="exam-search-container">
   <label for="exam-search" i18n><strong>Search for specific exams:</strong></label>

   <mat-form-field id="proctor-chat-name" class="text-field" appearance="outline">
      <div class="text-field-content">
         <input matInput (input)="search()" type="search" [placeholder]='placeHolder' id="exam-search" name="exam-search" [(ngModel)]="searchValue" />
         <button mat-icon-button matSuffix (click)="clearSearch()">X</button>
      </div>
   </mat-form-field>
</div>
