import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Service for dealing with Timing out the user.
 */
@Injectable({
  providedIn: 'root'
})
export class TimeoutService {

  /**
   * Behavior subject for doing the timeout.
   */
  public readonly timeoutOccurred = new EventEmitter<void>();

  /**
   * The browser interval that checks for whether the user should be timed out.
   */
  private interval: number = 0;

  /**
   * Key for key-value pair in local storage for when the timeout should be happen.
   */
  private static readonly TIMEOUT_KEY: string = 'OnDemand-timeoutTime';

  /**
   * Key for key-value pair in local storage for how long to wait until the timeout should happen.
   */
  private static readonly TIMEOUT_LENGTH: number = 900000;

  /**
   * Default constructer.
   *
   * @param router Angular Router object.
   */
  public constructor(
    private router: Router,
  ) { }

  /**
   * Initializes the timeout detections.
   */
  public init(): void {
    this.update();
    this.registerEventListeners();
    this.registerActionListening();
  }

  /**
   * Disables the timeout detections if they're ongoing.
   */
  public stop(): void {
    this.removeEventListeners();
    window.clearInterval(this.interval);
    localStorage.removeItem(TimeoutService.TIMEOUT_KEY);
  }

  /**
   * Does the actual timeout and navigates the user back to the login page.
   */
  public timeout(): void {
    this.stop();
    this.timeoutOccurred.emit();
    this.router.navigate([ '/login' ]);
  }

  /**
   * Compares the current time to the times in local storage. Determins whether the modal should display and whether
   * the timeout should occur.
   */
  private checkTimeout(): void {
    let now: number = Date.now();
    let timeoutTime: number = 0;

    try {
      timeoutTime = Number(localStorage.getItem(TimeoutService.TIMEOUT_KEY));
    } catch (e) {
      this.stop();
    }

    if (now >= timeoutTime) {
      this.timeout();
    }
  }

  /**
   * Registers the interval that checks against the timeout.
   */
  private registerActionListening(): void {
    this.interval = window.setInterval(() => {
      this.checkTimeout();
    }, 1000);
  }

  /**
   * Registers the timeout related event listeners for when the page is being interacted with.
   */
  private registerEventListeners(): void {
    addEventListener('mousemove', this.update);
    addEventListener('keydown', this.update);
    addEventListener('scroll', this.update);
    addEventListener('click', this.update);
  }

  /**
   * Removes timeout related event listeners on the page.
   */
  private removeEventListeners(): void {
    removeEventListener('mousemove', this.update);
    removeEventListener('keydown', this.update);
    removeEventListener('scroll', this.update);
    removeEventListener('click', this.update);
  }

  /**
   * Updates the values in local storage
   */
  private update(): void {
    localStorage.setItem(TimeoutService.TIMEOUT_KEY, (Date.now() + TimeoutService.TIMEOUT_LENGTH).toString());
  }
}
