import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ILanguage} from '../../../models/ILanguage';
import {LanguageService} from '../../../services/language.service';

@Component({
   selector: 'app-language-filter',
   templateUrl: './language-filter.component.html',
   styleUrls: ['./language-filter.component.scss']
})
export class LanguageFilterComponent implements OnInit {
   @Input() languageCode = '';
   @Output() onChangeLanguage = new EventEmitter<string>();
   languages: ILanguage[] = [];
   error = '';

   constructor(private languageService: LanguageService) {
   }

   ngOnInit(): void {
      this.getLanguages();
   }

   getLanguages() {
      this.languageService.getLanguages().subscribe((data: any) => {

         if (data != null) {
            this.languages = data.languages;
         }
      });
   }

   selectionChange(languageCode: string) {
      this.onChangeLanguage.emit(languageCode);
   }
}
