import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
   selector: 'app-prev-next',
   templateUrl: './prev-next.component.html',
   styleUrls: ['./prev-next.component.scss']
})
export class PrevNextComponent implements OnInit {
   @Input() nextMessage = $localize `Next`;
   @Input() nextHidden = '';
   @Output() clickNext = new EventEmitter<void>();
   @Output() clickPrevious = new EventEmitter<void>();
   @Input() previousHidden='';

   constructor() {
   }

   ngOnInit(): void {
   }

   next() {
      this.clickNext.emit();
   }

   previous() {
      this.clickPrevious.emit();
   }

   hideNext() {
      this.nextHidden = 'hidden';
   }

   displayNext() {
      this.nextHidden = '';
   }

   hidePrevious(){
      this.previousHidden='hidden';
   }

   displayPrevious(){
      this.previousHidden='';
   }

}
