<div class="yes-no-message-box">
   <div class="header-row">
      <h2 mat-dialog-title>{{message}}</h2>
   </div>
   <div class="body-row">

   </div>
   <div class="footer-row">
      <ctp-button class="msg-button" [style]="'primary'" (click)="handleClickYes()" i18n>
         Yes
      </ctp-button>
      <ctp-button class="msg-button" [style]="'secondary'" (click)="handleClickNo()" i18n>
         No
      </ctp-button>
   </div>
</div>

