<div class="survey-dialog-box">
   <div class="header-row">
      <p>
         <span i18n>Session Complete</span>
      </p>
   </div>
   <div class="body-row">
      <div class="survey-box">
         <img class="survey-img" src="assets/images/end_of_exam_survey.png" />
         <div class="survey-content">
            <p>
               <span i18n>Please take a moment to complete our survey.</span>
            </p>
            <p>
               <span i18n>
                  Your feedback will help us to continually improve the
                  proctoring and test taking experience.
               </span>
            </p>
            <p i18n>Thank you for using Compass Exams from Home.</p>
         </div>
      </div>
   </div>

   <div class="clear"></div>
   <div class="footer-row">
      <div class="btn-container">
         <button class="session-button" (click)="handleEndSession()" i18n>
            End session
         </button>
         <button class="survey-button" (click)="handleTakeSurvey()" i18n>
            Take Survey
         </button>
      </div>
   </div>
</div>
