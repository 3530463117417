<div class="table-header">
   <h2 class="program-name" i18n>Program Name</h2>
   <h2 class="product-name" i18n>Exam Name</h2>
</div>
<div class="container">
   <div *ngFor="let exam of searchResults" class="flex-row">
      <p class="program-name">{{exam.ProgramName}}</p>
      <p class="product-name"><a (click)="examSelected(exam.ExamSeriesCode)">{{exam.ProductName}}</a></p>
   </div>
</div>
