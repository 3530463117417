<div class="test-center-selection-container" [formGroup]="dateForm">
   <label class="exam-date-time" [ngClass]="error" i18n>
      Specified date*
   </label>
   <fa-icon [icon]="infoCircle" i18n-matTooltip matTooltip="The exact date you want your exam session to start."
            matTooltipPosition="right">
   </fa-icon>
   <br>
   <mat-form-field class="select-one" appearance="outline">
      <mat-label i18n>Choose a date</mat-label>
      <input matInput [matDatepicker]="dp" [min]="calenderStartDate" formControlName="date" required (dateInput)="isValid()" (focusout)="textBlur()">
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp (opened)="markOpened()" (closed)="isValid()"></mat-datepicker>
   </mat-form-field>
</div>
