import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import ICandidateVm from '../models/ICandidateVm';
import { AuthenticationService } from './authentication.service';
import { BASE_URL } from '../injection-tokens/base-url.token';
import IOutage from '../models/IOutage';

@Injectable({
   providedIn: 'root'
})
export class LabmanagerService {

   constructor(private httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, private _authenticationService: AuthenticationService) { }

   getCandidateVMs(accessCode: string) {
      return this.httpClient.get<ICandidateVm[]>(this.baseUrl + `api/Machines/?accessCode=${accessCode}`);
   }

   getNextOutage() {
      return this.httpClient.get<IOutage>(this.baseUrl + `api/Outages/Next`);
   }
}
