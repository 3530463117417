import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../constants/AppConstants';

@Component({
   selector: 'app-placeholder',
   templateUrl: './placeholder.component.html',
   styleUrls: ['./placeholder.component.scss']
})
export class PlaceholderComponent implements OnInit {

   //This component is a placeholder for strings that are not in use yet.

   Errors_System_outage: string = $localize`The system is currently experiencing technical difficulties.  Please refresh this page over the next couple of minutes.`;

   Phone: string = "1-800-555-1212";
   Errors_System_outage_subtext: string = $localize`If the problem persists contact technical support at ${this.Phone}`;

   Errors_no_TC_dashboard: string = $localize`You cannot create an exam session at this time because you are not associated with any test center.  Please ask your Organization Administrator for assistance.`;

   Errors_dashboard_start_system_errors: string = $localize`Our system is currently experiencing technical difficulties.  Please refresh this page in several minutes.  You may also contact technical support at x-xxx-xxx-xxxx.`;

   firstNum = 1;
   secondNum = 2;
   Candidates_in_exam: string = $localize `Candidates in exam (${this.firstNum} of ${this.secondNum})`;

   If_you_are_not_proctoring_this_session_info: string = $localize`If you are not proctoring this session, you will need to manually email the individual you intend to proctor this session and provide them the confirmation email.`;

   Exam_time_starttime_hour: string = $localize`Hour`;
   Exam_time_starttime_minutes: string = $localize`Minutes`;
   Exam_time_starttime_AM_PM: string = $localize`AM/PM`;

   constructor() { }

   ngOnInit(): void {
   }

}
