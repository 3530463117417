import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { AuthenticationService } from './services/authentication.service';
import { SpinnerService } from './services/spinner.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
   title = 'OnDemand';


   constructor(private _authenticationService: AuthenticationService, private _route: ActivatedRoute, private _spinner: SpinnerService, private _router: Router,private titleService:Title) {
      this.titleService.setTitle($localize`Exams from Home`);
    }

   ngOnInit(): void {}

}
