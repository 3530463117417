<div class="content-box helpful-resouce-container">
   <div class="content-box-header">
      <span class="title medium-bold" i18n>Helpful Resources</span>
   </div>
   <div class="content-box-body">
      <a href="https://certiport.pearsonvue.com/Support/Install/EFH/Exam-Administrator/EFH_Guide_ExamAdmin.pdf" title="user guide" target="_blank" i18n>User guide</a>
      <br />
      <a href="https://certiport.pearsonvue.com/Support/Install/EFH/Exam-Administrator/?ot=collapseSix"
         i18n-title title= "FAQ's"  target="_blank" i18n>FAQ's
      </a>
   </div>
</div>
