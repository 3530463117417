export enum TimeZone {
   ALASKA = 'Alaska',
   PACIFIC = 'Pacific Time (US & Canada)',
   MOUNTAIN = 'Mountain Time (US & Canada)',
   CENTRAL = 'Central Time (US & Canada)',
   EASTERN = 'Eastern Time (US & Canada)',
   BOGOTA = 'Bogota, Lima, Quito, Rio Branco',
   GUADALAJARA = 'Guadalajara, Mexico City, Monterrey',
   CARACAS = 'Caracas',
   CUIABA = 'Cuiaba',
   BUENOS_AIRES = 'City of Buenos Aires',
   GEORGETOWN = 'Georgetown, La Paz, Manaus, San Juan',
   MID_ATLANTIC = 'Mid-Atlantic - Old',
   AZORES = 'Azores',
   CABO_VERDE = 'Cabo Verde Is.',
   DUBLIN = 'Dublin, Edinburgh, Lisbon, London',
   WEST_CENTRAL_AFRICA = 'West Central Africa',
   CASABLANCA = 'Casablanca',
   SARAJEVO = 'Sarajevo, Skopje, Warsaw, Zagreb',
   CHISINAU = 'Chisinau',
   KUWAIT = 'Kuwait, Riyadh',
   ABU_DHABI = 'Abu Dhabi, Muscat',
   ISTANBUL = 'Istanbul',
   MOSCOW = 'Moscow, St. Petersburg',
   TBILISI = 'Tbilisi',
   BAKU = 'Baku',
   HAWAII = 'Hawaii',
   TEHRAN = 'Tehran',
   ISLAMABAD = 'Islamabad, Karachi',
   CHENNAI = 'Chennai, Kolkata, Mumbai, New Delhi',
   KATHMANDU = 'Kathmandu',
   DHAKA = 'Dhaka',
   YANGON = 'Yangon (Rangoon)',
   KUALA_LUMPUR = 'Kuala Lumpur, Singapore',
   BEIJING = 'Beijing, Chongqing, Hong Kong, Urumqi',
   SEOUL = 'Seoul',
   DARWIN = 'Darwin',
   CANBERRA = 'Canberra, Melbourne, Sydney',
   SOLOMON_IS = 'Solomon Is., New Caledonia',
   BRISBANE = 'Brisbane',
   ADELAIDE = 'Adelaide',
   FIJI = 'Fiji',
   AUCKLAND = 'Auckland, Wellington',
   NUKU_ALOFA = 'Nuku\'alofa',
   KIRITIMATI_IS = 'Kiritimati Island'
}
