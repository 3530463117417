<app-header-body-separator></app-header-body-separator>
<div class="component-container">
   <h1 i18n>Exams from Home System Error</h1>
   <div class="error-content">
      <ng-container [ngSwitch]="errorType">
         <ng-container *ngSwitchCase="errorTypeEnum.TOO_EARLY_TO_START">
            <p class="large-font" i18n>Your exam session cannot be started until {{examSessionSettings.earlyStartMinutes}} minutes prior to the start time.</p>
            <p class="small-font" i18n>Click “Start session”  when you are within the start session timeframe.</p>
            <ctp-button (click)="startSession()" i18n>Start session</ctp-button>
         </ng-container>
         <ng-container *ngSwitchCase="errorTypeEnum.WRONG_TEST_CENTER">
            <p class="large-font" i18n>You cannot proctor this exam session at this time because you are not associated with the test center.</p>
            <p class="large-font" i18n>Please ask your Organization Administrator for assistance.</p>
         </ng-container>
         <ng-container *ngSwitchCase="errorTypeEnum.WRONG_EXAM_CODE">
            <p class="large-font" i18n>Your exam session cannot start due to a technical issue.</p>
            <p class="large-font" i18n>Please try to start your exam session again.</p>
         </ng-container>
         <ng-container *ngSwitchCase="errorTypeEnum.SYSTEM_OUTAGE">
            <p class="large-font" i18n>The system is currently experiencing technical difficulties.</p>
            <p class="large-font" i18n>Please reschedule your exam session.</p>
            <ctp-button (click)="returnToDashboard()" style="'primary'" i18n>Return to dashboard</ctp-button>
         </ng-container>
         <ng-container *ngSwitchCase="errorTypeEnum.EXPIRED_ACCESS_CODE">
            <p class="large-font" i18n>The exam session (access code {{accessCode}}) has expired. Please schedule a new session.</p>
         </ng-container>
         <ng-container *ngSwitchDefault>
            <p class="large-font" i18n>Your exam session cannot start due to a technical issue.</p>
            <p class="large-font" i18n>Please try to start your exam session again.</p>
         </ng-container>
      </ng-container>
      <p class="small-font">
         <app-technical-support [showTechError]="true"></app-technical-support>
      </p>
   </div>
</div>
