import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { SpinnerService } from '../services/spinner.service';

@Injectable({
   providedIn: 'root'
})
export class AuthGuard implements CanActivate {
   constructor(private authService: AuthenticationService, private router: Router, private spinnerService: SpinnerService) { }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      if (route.queryParams.sid) {
         this.authService.Logout();
         return this.siginWithSid(route.queryParams.sid);
      }

      if (this.authService.Jwt) {
         if (!this.authService.Proctor) {
            return this.authService.FetchProctor().pipe(
               map(() => { return true; }),
               catchError((e, caught) => {
                  this.authService.Logout();
                  this.router.navigate(['login']);
                  return of(false);
               })
            )
         }
         return of(true);
      } else {
         this.router.navigate(['login']);
         return of(false);
      }
   }

   siginWithSid(sid: string): Observable<boolean> {
      this.spinnerService.show();
      return this.authService.AuthenticateWithSid(sid).pipe(
         tap(() => this.spinnerService.hide()),
         map(
            result => {
               if (result && this.authService.Jwt) {
                  this.router.navigate([''], {
                     queryParams: { 'sid': null },
                     queryParamsHandling: 'merge'
                  });
                  return true;
               }
               this.router.navigate(['login']);
               return false;
            }),
         catchError((err, caught) => {
            this.spinnerService.hide();
            this.router.navigate(['login']);
            return of(false);
         })
      );
   }
}
