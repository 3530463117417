import { Component, Input, OnInit } from '@angular/core';

import { AuthenticationService } from '../../services/authentication.service';

import ICandidateVm from '../../models/ICandidateVm';

@Component({
   selector: 'app-candidate-session',
   templateUrl: './candidate-session.component.html',
   styleUrls: ['./candidate-session.component.scss']
})
export class CandidateSessionComponent implements OnInit {
   @Input() candidateVM!: ICandidateVm;
   @Input() accessCode: any;
   @Input() chatName!: string;

   private restartTimerInterval: NodeJS.Timeout | undefined;
   constructor(private authService: AuthenticationService) {
   }

   ngOnInit(): void {
      this.authService.ProctorSource.subscribe(p => {
         this.chatName = `${p.FirstName} ${p.LastName}`;
      });
   }

   ngAfterViewInit() {
      // Persist restart timer across reloads
      let vmRestartTime = parseInt(sessionStorage.getItem(`vmRestartTimer_${this.candidateVM.symlink}`) ?? '-1', 10);
      if (!isNaN(vmRestartTime) && vmRestartTime > 0) {
         this.candidateVM.isRestarting = true;
         this.setRestartMessageTimer(vmRestartTime);
      }
   }

   ngOnDestroy() {
      if (this.restartTimerInterval)
         clearInterval(this.restartTimerInterval);
   }

   onRestartVMClick(failed: boolean) {
      this.candidateVM.isRestarting = !failed;
      if (this.restartTimerInterval)
         clearInterval(this.restartTimerInterval);

      if (!failed) {
         this.setRestartMessageTimer(95 * 1000);
      }
   }

   setRestartMessageTimer(miliseconds: number) {
      const intervalTime = 5 * 1000;
      sessionStorage.setItem(`vmRestartTimer_${this.candidateVM.symlink}`, miliseconds.toString());
      if (this.restartTimerInterval != null)
         clearInterval(this.restartTimerInterval);

      // Decrement restart timer storage on interval
      this.restartTimerInterval = setInterval(() => {
         let vmRestartTime = parseInt(sessionStorage.getItem(`vmRestartTimer_${this.candidateVM.symlink}`) ?? '-1', 10);
         // If restart timer complete
         if (isNaN(vmRestartTime) || vmRestartTime <= 0) {
            // Show vm
            this.candidateVM.isRestarting = false;
            let iframeElement = document.getElementById(`iframe_${this.candidateVM.symlink}`) as HTMLIFrameElement;
            iframeElement.src = iframeElement.src;
            // Clear restart timer
            sessionStorage.removeItem(`vmRestartTimer_${this.candidateVM.symlink}`);
            if (this.restartTimerInterval != null)
               clearInterval(this.restartTimerInterval);
         }
         else {
            // Decrement restart time
            sessionStorage.setItem(`vmRestartTimer_${this.candidateVM.symlink}`, (vmRestartTime - intervalTime).toString());
         }
      }, intervalTime);
   }
}
