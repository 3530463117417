<app-header-body-separator></app-header-body-separator>
<ng-container [ngSwitch]="currentPage">

   <div class="session-management-container">
      <app-alert *ngIf="showOutage" state="outage" i18n>
         A scheduled system maintenance outage is scheduled from {{outageStart+'Z' | date:'MMMM d, y h:mm a':'':locale}} to {{outageEnd+'Z' | date:'MMMM d, y h:mm a OOO':'':locale}}
      </app-alert>
      <app-alert *ngIf="showAlert" state="{{alertStyle}}">
         {{message}}
         <app-technical-support [showTechError]="showTechError"></app-technical-support>
      </app-alert>

      <app-dashboard *ngSwitchCase="0"
                     (onCreateSession)="createSession()"
                     (onStartSession)="startSession($event)"
                     (onSendConfirmationEmail)="sendConfirmationEmail($event)"
                     (onSessionDetails)="sessionDetails($event)"
                     (onCancelSession)="cancelSession($event)"
                     (onError)="postError($event)"
                     (onErrorResolved)="hasError = false" [prvSessionName]="prvSessionName"></app-dashboard>
      <app-wizard *ngSwitchCase="1" [currentPage]="wizardPage" (submitWizard)="onSubmitWizard($event)" [sessionName]="sessionName" (prvSessionName)="getPrvSessionName($event)"></app-wizard>
      <app-session-details *ngSwitchCase="2" (previous)="returnToDashboard()"
                           [sessionInfo]="this.examSessionInfo"></app-session-details>
   </div>
</ng-container>
