import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { SignalRChatService } from '../../services/signal-rchat.service';
import { IFullExamSession } from "../../models/IFullExamSession";
import { NameSessionComponent } from './name-session/name-session.component';
import { IExamSessionInput } from '../../models/IExamSessionInput';
import { ITestCenter } from '../../models/IProctorInfo';
import { AuthenticationService } from '../../services/authentication.service';
import { Subscription } from 'rxjs';

@Component({
   selector: 'app-dashboard',
   templateUrl: './dashboard.component.html',
   styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
   @Output() onCreateSession = new EventEmitter<void>();
   @Output() onStartSession = new EventEmitter();
   @Output() onSendConfirmationEmail = new EventEmitter();
   @Output() onSessionDetails = new EventEmitter();
   @Output() onCancelSession = new EventEmitter<string>();
   @Output() onError = new EventEmitter();
   @Output() onErrorResolved = new EventEmitter();
   @ViewChild(NameSessionComponent) nameSessionComponent = new NameSessionComponent();
   nameSession = {} as IExamSessionInput;
   testCenters!: ITestCenter[];
   hideCreateSession = true;
   private subscription!: Subscription;
   @Input() prvSessionName!: string;
   constructor(private _signalRChat: SignalRChatService, private authenticationService: AuthenticationService) { }

   ngOnInit(): void {

      this.subscription = this.authenticationService.ProctorSource.subscribe(proctor => {
         this.hideCreateSession = false;
         this.testCenters = proctor.testCenters
      });
      //   this._signalRChat.startConnection("123")
      //      .then(() => {
      //         this._signalRChat.addRefreshProctorIFrameListener(machineName => {
      //            alert(`RefreshProctorIFrame machine name: ${machineName}`);
      //         });
      //      });

      //   this._signalRChat.addReceiveMessageListener((machineName, displayName) => {
      //      alert(`ReceiveMessage machine name: ${machineName}, display name: ${displayName}`);
      //   });

      //   this._signalRChat.addReceiveLoggedInCandidatesListener((candidates) => {
      //      alert(`ReceiveLoggedInCandicates: ${candidates}`);
      //   });

      //   this._signalRChat.addRefreshProctorIFrameListener((machineName) => {
      //      alert(`ReceiveLoggedInCandicates: ${machineName}`);
      //   });
   }
   validate(): boolean {
      return this.nameSessionComponent.isValid()
   }
   validproctor(): boolean {
      if (this.testCenters?.length > 0) {
         return true;
      }
      return false;
   }

   public onClickCreateSession(): void {
      if (this.validproctor()) {
         if (this.validate()) {
            this.onErrorResolved.emit();
            this.onCreateSession.emit();
            this.nameSession.sessionName = this.nameSessionComponent.sessionName;
         }
         else {
            this.onError.emit($localize`Session name cannot be blank.`);
         }
      }
      else {
         this.onError.emit($localize`You cannot create an exam session at this time because you are not associated with any test center. Please ask your Organization Administrator for assistance.`);
      }
   }

   public sendConfirmationEmail($event: IFullExamSession): void {
      this.onSendConfirmationEmail.emit($event);
   }

   startSession($event: IFullExamSession) {
      this.onStartSession.emit($event);
   }

   sessionDetails($event: IFullExamSession) {
      this.onSessionDetails.emit($event);
   }

   cancelSession($event: string) {
      this.onCancelSession.emit($event);
   }
   sessionsInProgressMsg($event: string) {
      this.onError.emit($event);
   }
}
