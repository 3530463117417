<div class="test-center-selection-container" [formGroup]="timeZoneForm">
   <label class="exam-date-time" [ngClass]="error" i18n>
      Specify time zone
   </label>
   <fa-icon [icon]="infoCircle" i18n-matTooltip matTooltip="The time zone you are in or prefer to use when selecting your specific time."
            matTooltipPosition="right">
   </fa-icon>
   <br>
   <mat-form-field id="timeZone-selection" appearance="outline" class="select-one">
      <mat-label i18n>Select One</mat-label>
      <mat-select formControlName="timeZoneId" required (selectionChange)="onTimeZoneSelection($event.value)" (focusout)="isValid()">
         <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone.timeZoneId">
            <span>{{_translationService.getTimeZone(timeZone.displayName)}}</span>
         </mat-option>
      </mat-select>
   </mat-form-field>
</div>
