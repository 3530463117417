import { Injectable } from '@angular/core';
import { SignalRHub } from './SignalRHub';
import { environment } from './../../environments/environment';
import { symlink } from 'fs';
import ICandidateVm from '../models/ICandidateVm';
import { ConfigurationsService } from './configurations.service';

@Injectable({
   providedIn: 'root'
})
export class SignalRChatService {
   private _signalRHub: SignalRHub = new SignalRHub();
   constructor(private configurationsService: ConfigurationsService) { }

   public initConnection(): void {
      console.log("starting chat connection at " + this.configurationsService.ChatDomain)
      this._signalRHub.initialize(`https://${this.configurationsService.ChatDomain}/chat`);
   }

   public stopConnection(accessCode: string): Promise<void> {
      return this._signalRHub.invoke("LeaveRoom", accessCode, '"Todo--Provide machine/candidate-specific information."', true);
   }

   public updateProctor(accessCode: string): Promise<void> {
      return this._signalRHub.invoke("UpdateProctor", accessCode);
   }

   public start(accessCode: string): Promise<void> {
      return this._signalRHub.start()
         .then(() => {
            this._signalRHub.logID();
            this.subscribe(accessCode);
         })
         .catch((err: any) => {
            console.log('SignalR connection error in start:' + err);
         });
   }

   public addReceiveMessageListener(listener: (symlink: string, userName: string) => void): void {
      this._signalRHub.on('ReceiveMessage', (symlink, un) => {
         listener(symlink, un);
      });
   }

   public addReceiveLoggedInCandidateListener(listener: (symlink: string, displayName: string) => void): void {
      this._signalRHub.on('ReceiveLoggedInCandidate', (symlink, displayName) => {
         listener(symlink, displayName);
      });
   }

   public addRefreshProctorIFrameListener(listener: (symlink: string) => void): void {
      this._signalRHub.on('RefreshProctorIFrame', (symlink) => {
         listener(symlink);
      });
   }

   public logID(): void {
      this._signalRHub.logID();
   }

   private subscribe(accessCode: string): Promise<void> {
      console.log('Updating Proctor with code: ' + accessCode);
      return this._signalRHub.invoke("UpdateProctor", accessCode)
         .then(
            (x) => console.log("UpdateProctor success: " + x), //fulfilled
            (x) => console.error("UpdateProctor rejected: " + x) //reject
         )
         .catch(error => console.error("UpdateProctor error: " + error));
   }
}
