import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";

export const MY_FORMATS = {
   parse: {
      dateInput: 'LL',
   },
   display: {
      dateInput: 'LL',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
   },
};

@Component({
   selector: 'app-date-selection',
   templateUrl: './date-selection.component.html',
   styleUrls: ['./date-selection.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => DateSelectionComponent),
         multi: true
      },
      {
         provide: NG_VALIDATORS,
         useExisting: forwardRef(() => DateSelectionComponent),
         multi: true
      },
      {
         provide: DateAdapter,
         useClass: MomentDateAdapter,
         deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
      },

      { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
   ]
})
export class DateSelectionComponent implements OnInit, ControlValueAccessor, Validator {
   @Input() date: Date | string = "";
   infoCircle = faInfoCircle;
   error = '';
   private calendarOpened = false;
   public dateForm!: FormGroup;
   @Input() calenderStartDate: Date | undefined;
   @Output() valid: boolean = true;
   constructor() { }

   ngOnInit() {
      this.dateForm = new FormGroup({
         date: new FormControl(this.date ? new Date(this.date) : "", [Validators.required]),
      });
   }

   isValid() {
      this.valid = this.calendarOpened && this.dateForm.valid;
      if (this.dateForm.valid) {
         this.error = '';
         return true;
      }
      this.error = 'error';
      return false;
   }

   textBlur() {
      //Ugly code alert! When isValid() returns false, an error shows at top of screen.
      //We want to check isValid() when the textInput loses focus, but not if we're shifting focus to the calendar toggle.
      //This code executes, waits 100ms, then markOpened() gets called, which prevents isValid from being called, which prevents the error from showing.
      (async () => {
         await new Promise(resolve => setTimeout(resolve, 300));
         if (!this.calendarOpened) {
            this.isValid();
         }
      })();
   }

   markOpened() {
      this.calendarOpened = true;
   }

   onTouched: () => void = () => { };

   validate(control: AbstractControl): ValidationErrors | null {
      this.valid = this.dateForm.pristine || this.dateForm.valid;
      return this.dateForm.valid ? null : { invalidForm: { valid: false, message: $localize`Date selection is invalid` } };
   }
   writeValue(val: any): void {
      val && this.dateForm.setValue(val, { emitEvent: false });
   }
   registerOnChange(fn: any): void {
      this.dateForm.valueChanges.subscribe(fn);
   }
   registerOnTouched(fn: any): void {
      this.onTouched = fn;
   }
}
