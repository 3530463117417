import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../services/spinner.service';
import { WizardPage } from "../wizard/wizard.component";
import { SessionDetailsComponent } from "./session-details/session-details.component";
import { ExamSessionService } from "../services/exam-session.service";
import { IFullExamSession } from "../models/IFullExamSession";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SignalRChatService } from "../services/signal-rchat.service";
import { IExamSessionSettings } from "../models/IExamSessionSettings";
import { AuthenticationService } from "../services/authentication.service";
import { VmService } from "../services/vm.service";
import { DateTimeService } from "../services/date-time.service";
import { ExamService } from "../services/exam.service";
import { EmailService } from '../services/email.service';
import { WindowService } from '../services/window.service';
import { AppConstants } from '../constants/AppConstants';
import { LabmanagerService } from '../services/labmanager.service';
import { ConfigurationsService } from '../services/configurations.service';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

export enum MainPage {
   dashboard = 0,
   wizard = 1,
   details = 2
}

@Component({
   selector: 'app-session-management',
   templateUrl: './session-management.component.html',
   styleUrls: ['./session-management.component.scss']
})
export class SessionManagementComponent implements OnInit {
   @ViewChild(DashboardComponent) dashboardComponent = new DashboardComponent(this.signalRChatService, this.authenticationService);

   currentPage: MainPage = MainPage.dashboard;
   accessCode: any;
   sid!: string;
   hasError: boolean = false;
   alertStyle = 'error';
   showAlert = false;
   showOutage = false;
   outageMessage: string = '';
   message: string = '';
   wizardPage = 0;
   public examSessionInfo!: IFullExamSession;
   private authTokenSubscription!: Subscription;
   private examSessionSettings!: IExamSessionSettings;
   private sessionSettingsSubscription!: Subscription;
   private labMngrNextOutageSubscription!: Subscription;
   private vmServiceStatusSubscription!: Subscription;
   private authProctorSrcSubscription!: Subscription;
   private emailSrvcSendSubscription!: Subscription;
   sessionName = '';
   showTechError: boolean = false;
   outageStart?: Date;
   outageEnd?: Date;
   locale: string;
   prvSessionName: string = '';

   constructor(private route: Router,
      private activatedRoute: ActivatedRoute,
      private spinnerService: SpinnerService,
      private authenticationService: AuthenticationService,
      private examSessionService: ExamSessionService,
      private signalRChatService: SignalRChatService,
      private vmService: VmService,
      private timeZoneRegionService: DateTimeService,
      private examService: ExamService,
      private emailService: EmailService,
      private windowService: WindowService,
      private labManagerService: LabmanagerService,
      private configurationsService: ConfigurationsService,
      @Inject(LOCALE_ID) locale: string
   ) {
      this.locale = locale;
   }

   ngOnInit(): void {
      this.spinnerService.show();
      this.authTokenSubscription = this.authenticationService.JwtSource.subscribe(jwt => {
         if (jwt) {
            this.sessionSettingsSubscription = this.examSessionService.getExamSessionSettings().subscribe(sessionSettings => {
               this.examSessionSettings = sessionSettings;
            }, error => {
               console.log(`Failed to fetch Examsession Settings.`);
            });
         }
      }, error => {
         console.log(`Authentication failed to fetch Examsession Settings.`);
      });
      
      //Check LabManager for outages in the next week
      this.labMngrNextOutageSubscription = this.labManagerService.getNextOutage().subscribe(outage => {
         if (outage) {
            var showBeforeDate = new Date();
            showBeforeDate.setDate(showBeforeDate.getUTCDate() + this.configurationsService.OutageNotificationWindow);
            var parsedStart = new Date(Date.parse(outage.outageStartTime));

            if (parsedStart <= showBeforeDate) {
               this.outageStart = parsedStart;
               var parsedEnd = new Date(Date.parse(outage.outageEndTime));
               this.outageEnd = parsedEnd;
               this.showOutage = true;
            }
         }
      });
   }

   onSubmitWizard(accessCode: string): void {
      this.accessCode = accessCode;
      this.currentPage = MainPage.dashboard;
   }

   getPrvSessionName(prvSName: string) {
      this.prvSessionName = prvSName;
   }
   createSession(): void {
      this.sessionName = this.dashboardComponent.nameSessionComponent.sessionName;
      this.wizardPage = WizardPage.first;
      this.currentPage = MainPage.wizard;
      this.showAlert = false;
   }

   startSession($event: IFullExamSession) {
      let currentTime = new Date();
      currentTime = this.convertToUTCTime(currentTime);

      this.vmServiceStatusSubscription =  this.vmService.checkStatus($event.accessCode).subscribe(x => {
         if (x) {
            let startTimes = this.getStartTimes($event);

            if (currentTime.getTime() < startTimes.earliestStartTime.getTime()) {
               this.postError($localize`Your exam session cannot be started until ${this.examSessionSettings.earlyStartMinutes} minutes prior to the start time.`);
            } else if (currentTime.getTime() > startTimes.latestStartTime.getTime() && $event.examState.toLowerCase() != 'started') {
               this.postError($localize`Your exam session cannot be started more than ${this.examSessionSettings.lateStartMinutes} minutes after the start time.`);
            } else {
               this.hasError = false;
               this.startSessionInLabManager($event);
            }
         } else {
            this.postError($localize`We are experiencing systems issues and are unable to start your session. Please refresh the page and try again.`, true);
         }
      });
   }

   sendConfirmationEmail($event: IFullExamSession) {
      this.showAlert = false;
      this.authProctorSrcSubscription = this.authenticationService.ProctorSource.subscribe(proctor => {
         this.spinnerService.show();
         this.emailSrvcSendSubscription = this.emailService.sendEmail($event.accessCode).subscribe(_ => {
            this.spinnerService.hide();
            this.postSuccess($localize`Email successfully sent. Follow the instructions in the email before starting the exam.`);
         },
            error => {
               this.postError($localize`The system failed to send the email. You can try again.`, false);
            });
      });
   }

   sessionDetails($event: IFullExamSession) {
      this.currentPage = MainPage.details;
      this.examSessionInfo = $event;
      this.showAlert = false;
   }

   cancelSession($event: string) {
      if ($event)
         this.postError($event);
   }

   returnToDashboard() {
      this.currentPage = MainPage.dashboard;
   }

   private convertToUTCTime(date: Date) {
      var utcDateTime = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      return utcDateTime;
   }

   private getStartTimes($event: IFullExamSession) {
      let examStartTime = new Date($event.startTime);
      let earliestStartTime = new Date(examStartTime);
      let latestStartTime = new Date(examStartTime);
      earliestStartTime.setMinutes(earliestStartTime.getMinutes() - this.examSessionSettings.earlyStartMinutes);
      latestStartTime.setMinutes(latestStartTime.getMinutes() + this.examSessionSettings.lateStartMinutes);
      return { earliestStartTime, latestStartTime };
   }

   startSessionInLabManager($event: IFullExamSession) {
      this.windowService.open('/exams-view?accessCode=' + $event.accessCode);
   }

   postError(message: string, showTechError = false) {
      this.showAlert = true;
      this.alertStyle = 'error';
      this.message = message;
      this.showTechError = showTechError;
   }

   postSuccess(message: string) {
      this.showAlert = true;
      this.alertStyle = 'success';
      this.message = message;
      this.showTechError = false;
   }

   hasSubscriptions() {
      return (
         this.sessionSettingsSubscription ||
         this.authTokenSubscription ||
         this.labMngrNextOutageSubscription ||
         this.vmServiceStatusSubscription ||
         this.authProctorSrcSubscription ||
         this.emailSrvcSendSubscription
         );
   }

   clearSubscriptions() {
      if (this.sessionSettingsSubscription) {
         this.sessionSettingsSubscription.unsubscribe();
      }

      if (this.authTokenSubscription) {
         this.authTokenSubscription.unsubscribe();
      }

      if (this.labMngrNextOutageSubscription) {
         this.labMngrNextOutageSubscription.unsubscribe();
      }

      if (this.vmServiceStatusSubscription) {
         this.vmServiceStatusSubscription.unsubscribe();
      }

      if (this.authProctorSrcSubscription) {
         this.authProctorSrcSubscription.unsubscribe();
      }

      if (this.emailSrvcSendSubscription) {
         this.emailSrvcSendSubscription.unsubscribe();
      }

   }
   ngOnDestroy(): void {
      if (this.hasSubscriptions()) {
         this.clearSubscriptions();
      }
   }
}
