import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-management-page',
  templateUrl: './session-management-page.component.html',
  styleUrls: ['./session-management-page.component.scss']
})
export class SessionManagementPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
