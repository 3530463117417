import { InjectionToken } from '@angular/core';

/**
 * InjectionToken used to retrieve the base url of the application.
 *
 * This is set to be empty so that all REST requests from the application are properly routed to the backend
 * without appending the locale.
 */
export const BASE_URL = new InjectionToken<string>('BASE_URL', {
  providedIn: 'root',
  factory: () => '/'
});
