<div class="language-selection-container">
   <label for="language-selection" class="exam-language" [class.error]="hasError" i18n>Exam language *
      <fa-icon [icon]="infoCircle" i18n-matTooltip matTooltip="Only supported languages displayed." matTooltipPosition="right">
      </fa-icon>
   </label>
   <br>
   <mat-form-field id="language-selection" appearance="outline" class="select-one">
      <mat-label i18n>Select one</mat-label>
      <mat-select (selectionChange)="selectionChange($event.value)" [(ngModel)]="languageCode" (focusin)="validateInline()" (focusout)="validateInline()" required>
         <mat-option *ngFor="let lang of languages" value="{{lang.languageCode}}">
            {{lang.languageName}}
         </mat-option>
      </mat-select>
   </mat-form-field>
</div>
