<app-session-info [candidateVMInfo]="candidateVM" [accessCode]=accessCode (onRestartVMClick)="onRestartVMClick($event)"></app-session-info>
<div class="panel-body">
   <table class="candidate-session-table">
      <tr>
         <td>
            <div [hidden]="candidateVM.isRestarting">
               <div *ngIf="!candidateVM.examCompleted" class="comp-container">
                  <app-candidate-vm [candidateVM]="candidateVM"></app-candidate-vm>
               </div>
               <div *ngIf="candidateVM.examCompleted" class="exam-completed-cell comp-container">
                  <app-candidate-exam-complete></app-candidate-exam-complete>
               </div>
            </div>
            <div [hidden]="!candidateVM.isRestarting" class="exam-completed-cell comp-container">
               <app-candidate-vm-restart-message></app-candidate-vm-restart-message>
            </div>
         </td>
         <td>
            <app-chat-box id="chat_{{candidateVM.symlink}}" [chatName]="chatName" [candidateVM]="candidateVM"></app-chat-box>
         </td>
      </tr>
   </table>
</div>
