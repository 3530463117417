import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import { BASE_URL } from '../injection-tokens/base-url.token';

@Injectable({
  providedIn: 'root'
})
export class VmService {
  constructor(private httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, private _authenticationService: AuthenticationService) { }

   checkStatus(accessCode: string) {
      const url = this.baseUrl + `api/machines/CheckStatus/?accessCode=${accessCode}`;
      return this.httpClient.get<boolean>(url);
   }
}
