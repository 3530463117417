<app-header-body-separator></app-header-body-separator>
<ng-container>
   <div class="app-wrapper">
      <div class="panel">
         <app-alert *ngIf="hasError" [state]="alertStyle">{{isForbidden ? forbiddenMsg : errorMsg}}</app-alert>
         <div class="panel-body">
            <div class="intro">
               <div class="title-block">
                  <h1 id="lbl_welcome" i18n>Welcome</h1>
                  <h2 id="loginMessage" i18n>Compass Exams from Home Administration</h2>
                  <p class="required-field-message" i18n><i>(* indicates a required field)</i></p>
               </div>
            </div>

            <app-login-form [loading]="loading" (submit)="onSubmit($event)"></app-login-form>

            <div class="link-area">
               <p><span i18n>Forgot your username and password? </span><a [href]="recoveryUrl" target="_blank" i18n>I cannot access my account</a></p>
               <p><span i18n>Need Support? </span> <a [href]="candidateSupportUrl" target="_blank" i18n>Test Candidate Support</a></p>
               <p><span i18n>More Info? </span><a [href]="efhInfoUrl" target="_blank" i18n>Exams from Home Info</a></p>
            </div>
         </div>
      </div>
   </div>
</ng-container>
