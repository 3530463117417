import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { BASE_URL } from '../injection-tokens/base-url.token';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class EmailService {

   constructor(private httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, private _authenticationService: AuthenticationService) { }

   sendEmail(accessCode: string, emailAddress = ''): Observable<any> {
      return this.httpClient
         .post(this.baseUrl + `api/Email?accessCode=${accessCode}&proctorEmail=${emailAddress}`, {});
   }
}
