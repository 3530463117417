import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ITestCenter } from "../../models/IProctorInfo";
import { SpinnerService } from "../../services/spinner.service";
import { LanguageService } from "../../services/language.service";
import { TestCenterSelectionComponent } from "./test-center-selection/test-center-selection.component";
import { LanguageSelectionComponent } from "./language-selection/language-selection.component";
import { CandidateCountComponent } from "./candidate-count/candidate-count.component";
import { IExamSessionInput } from "../../models/IExamSessionInput";
import { AuthenticationService } from "../../services/authentication.service";
import { ExamSessionService } from '../../services/exam-session.service';
import ValidationStatus from "../../models/ValidationStatus";
import { ILanguage } from '../../models/ILanguage';
import { error } from 'console';

@Component({
   selector: 'app-exam-input-basic-info',
   templateUrl: './exam-input-basic-info.component.html',
   styleUrls: ['./exam-input-basic-info.component.scss']
})
export class ExamInputBasicInfoComponent implements OnInit {
   message = '';
   alertHidden = '';
   hasError = false;
   errorMsg = $localize`Error. Please contact admin`;
   oneOrMoreErrorMessage = $localize`One or more errors have been found in the field(s) indicated below. All errors must be corrected.`;
   testCenters!: ITestCenter[];
   proctorChatNameIsValid = false;
   testCenterSelectionIsValid = false;
   languageSelectionIsValid = false;
   candidateCountIsValid = false;
   LanguageListError = $localize`The system failed to retrieve the language list`;
   languages: ILanguage[] = [];
   @Output() nextClick = new EventEmitter();
   @Input() existingInformation!: IExamSessionInput;
   @ViewChild(TestCenterSelectionComponent) testCenterSelectionComponent = new TestCenterSelectionComponent(this.authenticationService);
   @ViewChild(LanguageSelectionComponent) languageSelectionComponent = new LanguageSelectionComponent(this.languageService);
   @ViewChild(CandidateCountComponent) candidateCountComponent = new CandidateCountComponent(this.examSessionService);
   private validTestCenter = true;
   private validCandidateCount = { IsValid: true, ErrorMessage: '' } as ValidationStatus;
   private validLanguage = true;

   constructor(private spinnerService: SpinnerService,
      private authenticationService: AuthenticationService,
      private languageService: LanguageService,
      private examSessionService: ExamSessionService) {
   }
    ngOnInit(): void {
       this.languageService.getLanguages().subscribe((data: any) => {

          if (data != null) {
             this.languages = data.languages;
          } 
       },
          error => {
             this.errorMsg = this.LanguageListError
             this.hasError = true;
          });
    }

   validate(): boolean {
      this.validateTestCenter();
      this.validateLanguage();
      this.validateCandidateCount();

      return this.validTestCenter && this.validLanguage && this.validCandidateCount.IsValid;
   }

   public validateTestCenter() {
      this.validTestCenter = this.testCenterSelectionComponent.isValid();
      this.postError();


   }

   public validateLanguage() {
      this.validLanguage =this.languageSelectionComponent.isValid();
      this.postError();
   }

   public validateCandidateCount() {
      this.validCandidateCount = this.candidateCountComponent.ValidateCandidateCount();
      this.postError();
   }

   private postError() {
      let errorMessage = '';
      this.hasError = false;

      if (!this.validTestCenter || !this.validLanguage) {
         errorMessage += this.oneOrMoreErrorMessage + ' ';
      }
      if (!this.validCandidateCount.IsValid) {
         errorMessage += this.validCandidateCount.ErrorMessage;
      }     

      if (errorMessage !== '') {
         this.hasError = true;
         this.errorMsg = errorMessage;
      }
   }
}
