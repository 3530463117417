<div class="app-header">
   <div class="main-content">
      <div>
         <img src="/images/CertiportLogo-White2.png" alt="Certiport" />
      </div>

      <ctp-text-dropdown *ngIf="loggedIn" [options]="userOptions" (optionClick)="handleUserOptionClick($event)">
         <div class="div-user-container">
            <div class="div-user-icon">
               <fa-icon [icon]="userSolidIcon"></fa-icon>
            </div>

            <div class="div-user">
               <p>{{proctorName}}</p>
            </div>
         </div>
      </ctp-text-dropdown>
   </div>
<!--   <div class="header-body-separator"></div>-->
</div>


