/**
 * Enum for different types of Start Session Errors.
 */
export enum SystemErrorType {
  /**
   * Represents an Unknown errorType.
   */
  UNKNOWN = 'unknown',

  /**
   * Represents receiving an incorrect exam code.
   */
  WRONG_EXAM_CODE = 'wrongExamCode',

  /**
   * Represent receiving an incorrect test center.
   */
  WRONG_TEST_CENTER = 'wrongTestCenter',

  /**
   * Represent too early to start a session
   */
   TOO_EARLY_TO_START = 'tooEarlyToStart',

   /**
    * Not enough machines for the exam session
    */
   SYSTEM_OUTAGE = 'systemOutage',


   /**
    * Expired access code
    */
   EXPIRED_ACCESS_CODE = 'expiredAccessCode',
}
