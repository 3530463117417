import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {faInfoCircle} from "@fortawesome/pro-solid-svg-icons";
import {AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators} from "@angular/forms";
import {ITimeZone} from "../../../models/ITimeZone";
import {TranslationService} from "../../../services/translation.service";
import { DateTimeService } from '../../../services/date-time.service';

@Component({
   selector: 'app-time-zone-selection',
   templateUrl: './time-zone-selection.component.html',
   styleUrls: ['./time-zone-selection.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => TimeZoneSelectionComponent),
         multi: true
      },
      {
         provide: NG_VALIDATORS,
         useExisting: forwardRef(() => TimeZoneSelectionComponent),
         multi: true
      }
   ]
})
export class TimeZoneSelectionComponent implements OnInit, Validator, ControlValueAccessor {
   @Input() timeZoneId = -1;
   timeZones: ITimeZone[] = [];
   infoCircle = faInfoCircle;
   error = '';
   public timeZoneForm!: FormGroup;
   @Output() calenderStartDate = new EventEmitter<Date>();
   @Output() valid: boolean = true;

   constructor(public _translationService: TranslationService, private _timeZoneRegionService: DateTimeService) { }

   ngOnInit(): void {
      this.timeZoneForm = new FormGroup({
         timeZoneId: new FormControl(this.timeZoneId, [Validators.required, Validators.min(1)])
      });
      this.calenderStartDate.emit(new Date());
      this.timeZoneForm.controls['timeZoneId'].valueChanges.subscribe(zoneId => {
         this.timeZoneId=zoneId;
         this.onTimeZoneSelection(zoneId);
      });
   }
 
   isValid() {
      this.valid = this.timeZoneForm.untouched || this.timeZoneForm.valid;
      if (this.valid) {
         this.error = '';
         return true;
      }
      this.error = 'error';
      return false;

   }

   onTouched: () => void = () => { };

   validate(control: AbstractControl): ValidationErrors | null {
      return this.timeZoneForm.valid ? null : {
         invalidForm: { valid: false, message: $localize`timezone selection is invalid` }
      };
   }
   writeValue(val: any): void {
      val && this.timeZoneForm.setValue(val, { emitEvent: false });
   }
   registerOnChange(fn: any): void {
      this.timeZoneForm.valueChanges.subscribe(fn);
   }
   registerOnTouched(fn: any): void {
      this.onTouched = fn;
   }

   onTimeZoneSelection(selectedtimeZoneId: number) {
      this.isValid();
      let selectWinTimeZoneId = this.timeZones.find(a => a.timeZoneId == selectedtimeZoneId)?.windowsTimeZoneId as string;
      this._timeZoneRegionService.getCurrentDateByWindowsTimeZoneId(selectWinTimeZoneId).subscribe((data: any) => {

         if (data != null) {
            this.calenderStartDate.emit(data);
         }
      });
   }   
}
