<div class="wizard-container">
   <ng-container [ngSwitch]="currentPage">
      <app-exam-input-basic-info *ngSwitchCase="0" [existingInformation]="sessionInfo"></app-exam-input-basic-info>
      <app-exam-selection *ngSwitchCase="1" [languageCode]="sessionInfo.language" [examId]="sessionInfo.examId" (selectLanguage)="onSelectLanguage($event)" (selectExam)="onSelectExam($event)"></app-exam-selection>
      <app-exam-date-time-and-region *ngSwitchCase="2" [existingInformation]="sessionInfo" [submissionError]="submissionError" [windowsCurrentTimeZoneId]="windowsCurrentTimeZoneId" (formCompleted)="onFormCompleted()"></app-exam-date-time-and-region>
      <app-session-confirmation *ngSwitchCase="3" [sessionInfo]="sessionInfo" [accessCode]="sessionInfo.accessCode" (emailSent)="onEmailSent()"></app-session-confirmation>
   </ng-container>
</div>
<app-prev-next (clickNext)="onClickNext()" (clickPrevious)="onClickPrevious()" [nextMessage]="getNextText()"></app-prev-next>

