import { Component, Input, OnInit} from '@angular/core';

@Component({
   selector: 'app-name-session',
   templateUrl: './name-session.component.html',
   styleUrls: ['./name-session.component.scss']
})
export class NameSessionComponent implements OnInit {
   @Input() sessionName: string = '';
   error = '';
   private hasError = false;
   constructor() {

   }

   ngOnInit(): void {
   }

   isValid(): boolean {
      this.hasError = false;
      if(this.sessionName === '' || this.sessionName === undefined){
         this.error = 'error';
         this.hasError = true;
      }
      return !this.hasError;
   }

}
