import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

export interface IOption {
   optionNum: number,
   text: string
}

@Component({
  selector: 'ctp-dropdown',
  templateUrl: './ctp-dropdown.component.html',
  styleUrls: ['./ctp-dropdown.component.scss']
})
export class CtpDropdownComponent implements OnInit {
   @Output() onOptionClick = new EventEmitter<number>();
   @Input() options!: IOption[];

   caretDown = faCaretDown;

  constructor() { }

  ngOnInit(): void {
  }

  optionClicked(option: number) {
     this.onOptionClick.emit(option)
  }
}
